<template>
  <section id="roadmap">
    <div class="container">
      <div class="title">
        <h3>{{ $t("TMNG roadmap<") }}</h3>
      </div>
      <div class="boxs">
        <div
          v-wow="{
            'animation-name': 'fadeInUp',
            'animation-duration': '1s',
          }"
          class="box"
        >
          <h4 class="date">{{ $t("November 4th, 2022<") }}</h4>
          <h6>
            {{ $t("Indium Technology Metal<") }}
          </h6>
          <p>
            <b>1.</b>
            {{
              $t(
                " What is Indium? Indium is a technology metal with huge potential. It is element 49 on the periodic table of elements and it is mined in China and delivered in a bar form. It is a silver shiny lustr... <"
              )
            }}
          </p>
          <button @click="isModal = true">
            <span>{{ $t("Read More<") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="#fff"
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
              />
            </svg>
          </button>
        </div>
        <div
          v-wow="{
            'animation-name': 'fadeInUp',
            'animation-duration': '1s',
          }"
          class="box"
        >
          <h4 class="date">{{ $t("September 22nd, 2022<") }}</h4>
          <h6>
            {{ $t("Tradition Meets Crypto<") }}
          </h6>
          <p>
            <b>2.</b>
            {{
              $t(
                " In less than 10 days, on October 1st, 2022, EREA World AG is hosting an event in the city of Finance - Frankfurt am Main. This event has  been organized by the EREA World AG team and the founders of TM...<"
              )
            }}
          </p>
          <button @click="isModal = true">
            <span>{{ $t("Read More<") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="#fff"
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
              />
            </svg>
          </button>
        </div>
        <div
          v-wow="{
            'animation-name': 'fadeInUp',
            'animation-duration': '1s',
          }"
          class="box"
        >
          <h4 class="date">{{ $t("August 15 th, 2022<") }}</h4>
          <h6>
            {{
              $t(
                "TMNG schlagt die Brucke zwischen der alten und neuen Welt der Investitionen<"
              )
            }}
          </h6>
          <p>
            <b>3.</b>
            {{
              $t(
                "Traditionell haben die Menschen in Sachwerte wie Gold und Silber investiert, um ihr Vermogen vor Inflation zu schutzen. Andere haben in Lebensversicherungen, Aktien, Anleihen oder andere liquide Mitte..<"
              )
            }}
          </p>
          <button @click="isModal = true">
            <span>{{ $t("Read More<") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="#fff"
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        v-wow="{
          'animation-name': 'fadeInUp',
          'animation-duration': '1s',
        }"
        class="text-center"
      >
        <button class="view-more" @click="isModal = true">
          {{ $t("View more<") }}
        </button>
      </div>
      <div v-if="isModal" class="modal">
        <div class="modal-box">
          <button class="close bg-none cursor-pointer" @click="isModal = false">
            <img src="@/assets/images/close-line.svg" :alt="$t('Imprint<')" />
          </button>
          <img
            v-lazy="require('@/assets/images/roadmapv2.png')"
            :alt="$t('TMN Road Map<')"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isModal: false,
    };
  },
};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
  .modal {
    width: 100%;
    height: 100%;
    background: #00000078;
    z-index: 16;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    padding: 15px;

    &-box {
      max-width: 1200px;
      width: 100%;
      margin: auto;
      padding: 15px;
      position: relative;
      .close {
        width: 50px;
        height: 50px;
        color: var(--white);
        background: #224999;
        position: absolute;
        top: 15px;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 640px) {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
  .title {
    text-align: center;
    h3 {
      color: var(--white);
      border: 2px solid var(--light-green);
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      display: inline-block;
      padding: 10px 50px;
      border-radius: 40px;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      @media screen and (max-width: 1030px) {
        font-size: 20px;
      }
    }
  }
  .boxs {
    padding-top: 100px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &:before {
      content: "";
      width: 1px;
      height: 100%;
      border-left: 1px dashed var(--light-green);
      position: absolute;
      left: calc(50% - 0.5px);
      top: 0;
    }
    .box {
      width: 50%;
      .date {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        margin-bottom: 20px;
        position: relative;
        font-size: 17px;
        @media screen and (max-width: 1030px) {
          font-size: 14px;
        }
        @media screen and (max-width: 640px) {
          font-size: 12px;
        }
        &:before {
          content: "";
          width: 65%;
          height: 1px;
          border-bottom: 1px dashed var(--light-green);
          @media screen and (max-width: 1030px) {
            width: 50%;
          }
          @media screen and (max-width: 640px) {
            width: 10%;
          }
        }
      }
      h6 {
        color: var(--white);
        font-weight: 500;
        margin-bottom: 20px;
        font-family: "Poppins", sans-serif;
        @media screen and (max-width: 1100px) {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
      p {
        color: var(--white);
        font-weight: 300;
        margin-bottom: 30px;
        font-family: "Poppins", sans-serif;
        @media screen and (max-width: 1100px) {
          font-size: 12px;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 640px) {
          font-size: 10px;
        }
      }
      button {
        padding: 8px 20px;
        border: 1px solid var(--light-green);
        color: var(--white);
        font-family: "Poppins";
        max-width: 170px;
        width: 100%;
        background: none;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        @media screen and (max-width: 1030px) {
          padding: 7px 20px;
          font-size: 12px;
        }
        i {
          color: var(--light-green);
          font-size: 23px;
          line-height: normal;
          @media screen and (max-width: 640px) {
            font-size: 17px;
          }
        }
      }
      &:nth-child(odd) {
        padding-right: 50px;
        margin-top: -100px;
        .date {
          color: var(--light-green);
          &:before {
            position: absolute;
            right: -48px;
            top: calc(50% - 0.5px);
            @media screen and (max-width: 640px) {
              right: -8px;
            }
          }
        }
        @media screen and (max-width: 640px) {
          padding-right: 10px;
        }
      }
      &:nth-child(even) {
        padding-top: 200px;
        padding-left: 50px;
        text-align: right;
        .date {
          color: #224999;
          &:before {
            position: absolute;
            left: -48px;
            top: calc(50% - 0.5px);
            @media screen and (max-width: 640px) {
              left: -8px;
            }
          }
        }
        button {
          float: right;
        }
        @media screen and (max-width: 640px) {
          padding-left: 10px;
        }
      }
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .view-more {
    margin-top: 20px;
    padding: 12px 34px;
    background: var(--light-green);
    color: var(--white);
    font-family: "Poppins", sans-serif;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    cursor: pointer;
    @media screen and (max-width: 1030px) {
      padding: 8px 35px;
    }
  }
}
</style>
