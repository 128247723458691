<template>
  <section
    id="tmng-token-sale-event"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="container">
      <h5>
        {{ $t("Benefits to participating in the TMNG Token Sale Event<") }}
      </h5>
      <div v-if="!loading" class="state">
        <h6>
          <i class="ri-restart-line"></i>
          {{ $t("Loading...<") }}
        </h6>
      </div>
      <div v-else ref="swiper" class="swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="comment in comments"
            :key="comment.id"
            class="swiper-slide"
          >
            <img v-lazy="comment.photo" :alt="comment.fullName" />
            <p>{{ getLang(comment.translations) }}</p>
            <span>{{ comment.fullName }}</span>
          </div>
        </div>
      </div>
      <div v-if="comments.length > 1" class="comment-arrow">
        <div class="swiper-button-prev" @click="prev">
          <img
            v-lazy="require('@/assets/images/arrow-left-s-line.svg')"
            class="event-none"
            :alt="$t('Arrow<')"
          />
        </div>
        <div class="swiper-button-next" @click="next">
          <img
            v-lazy="require('@/assets/images/arrow-right-s-line.svg')"
            class="event-none"
            :alt="$t('Arrow<')"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      swiper: null,
      comments: [],
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getLang(t) {
      const answer = t.find(
        (x) => this.$store.state.selectedLanguage === x.language
      );
      return answer.translation;
    },
    getList() {
      fetch(`${process.env.VUE_APP_API_URL}/public/comments`)
        .then((res) => res.json())
        .then((data) => (this.comments = data))
        .then(() => (this.loading = true), this.init());
    },
    init() {
      setTimeout(() => {
        this.startSwiper();
      }, 1000);
    },
    next() {
      this.swiper.slideNext(1000);
    },
    prev() {
      this.swiper.slidePrev(1000);
    },
    startSwiper() {
      if (this.swiper) {
        this.swiper.destroy();
      }
      this.$nextTick(() => {
        this.swiper = new this.$Swiper(this.$refs.swiper, {
          spaceBetween: 30,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0;
  background: var(--light-green);
  text-align: center;
  -webkit-box-shadow: 0px 19px 22px 0px #00000026;
  -moz-box-shadow: 0px 19px 22px 0px #00000026;
  box-shadow: 0px 19px 22px 0px #00000026;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
  h5 {
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: var(--white);
    font-size: 24px;
    margin-bottom: 20px;
    @media screen and (max-width: 1100px) {
      font-size: 20px;
    }
  }
  .swiper-slide {
    img {
      height: 120px;
      @media screen and (max-width: 640px) {
        height: 80px;
      }
    }
    p {
      margin: 10px 0 5px 0;
    }
    span {
      display: block;
      text-align: center;
      color: var(--white);
      font-weight: 600;
      font-family: "Poppins", sans-serif;
    }
  }
  .state {
    height: 150px;
    padding: 20px;
    overflow: hidden;
    text-align: center;
    background: linear-gradient(
      90deg,
      #a6fbff3d 0%,
      #73ecf24d 5%,
      #062e6f42 100%
    );
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      color: var(--white);
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 13px;
    }

    &::after {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        90deg,
        #eeeeee00 0,
        #0a9b9b03 1px,
        #eeeeee00 0,
        #eeeeee29 50%
      );
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      -moz-animation: shimmer 5s infinite;
      -ms-animation: shimmer 5s infinite;
      -o-animation: shimmer 5s infinite;
      -webkit-animation: shimmer 5s infinite;
      animation: shimmer 5s infinite;

      @-moz-keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }
      @-o-keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }

      @-webkit-keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }
      @keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }
    }
  }
  p {
    color: #224999;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }
  .comment-arrow {
    width: 90px;
    margin: 40px auto 0 auto;
    position: relative;
    .swiper-button-prev,
    .swiper-button-next {
      width: 30px;
      height: 30px;
      border: 1px solid var(--white);
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      color: var(--white);
      font-size: 20px;
      &:after {
        display: none;
      }
    }
  }
}
</style>
