<template>
  <div :key="$store.state.version" class="wrapper" id="app">
    <Header />
    <Banner />
    <Video v-if="isYoutube" />
    <div class="container" v-else>
      <div class="youtube-box">
        <a href="https://www.youtube.com/@tmnglobalofficial" target="_blank">
          <img
            v-lazy="require('@/assets/images/youtube-line.svg')"
            alt="tmn-youtube"
          />
          <h6>TMN Global</h6>
          <p>@tmnglobalofficial</p>
          <button>{{ $t("Our Youtube Channel<") }}</button>
        </a>
      </div>
    </div>
    <OurAmmbassadors />
    <Ag />
    <Vision />
    <Token />
    <Comments />
    <TSE />
    <AgMetalsAbout />
    <News />
    <Providers />
    <OurTeam />
    <Community />
    <RoadMap />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Banner from "./components/Banner.vue";
import Video from "./components/Video.vue";
import OurAmmbassadors from "./components/OurAmmbassadors.vue";
import Ag from "./components/Ag.vue";
import Vision from "./components/Vision.vue";
import Token from "./components/Token.vue";
import Comments from "./components/Comments.vue";
import TSE from "./components/TSE.vue";
import AgMetalsAbout from "./components/AgMetalsAbout.vue";
import News from "./components/News.vue";
import Providers from "./components/Providers.vue";
import OurTeam from "./components/OurTeam.vue";
import Community from "./components/Community.vue";
import RoadMap from "./components/RoadMap.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Banner,
    Video,
    OurAmmbassadors,
    Ag,
    Vision,
    Token,
    Comments,
    TSE,
    AgMetalsAbout,
    News,
    Providers,
    OurTeam,
    Community,
    RoadMap,
    Footer,
  },
  data() {
    return {
      version: 0,
    };
  },
  computed: {
    isYoutube() {
      if (this.$isYoutube) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.youtube-box {
  margin: 0 auto;
  padding: 100px 50px 50px 50px;
  @media screen and (max-width: 640px) {
    padding: 100px 0 50px 0;
  }
  a {
    background: linear-gradient(
      90deg,
      #a7faff00 0%,
      #73ecf214 5%,
      #062e6f03 100%
    );
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    box-shadow: 0px 0px 47px 0px #00000012;

    h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      color: var(--white);
      margin-bottom: 0;
    }
    p {
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      color: var(--white);
      font-size: 11px;
    }
    button {
      height: 40px;
      display: inline-flex;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      width: 50%;
      font-size: 12px;
      margin: 15px auto 0 auto;
      background: #e9e9e924;
      color: #fff;
      @media screen and (max-width: 640px) {
        width: 80%;
      }
    }
    img {
      height: 150px;
    }
  }
}
</style>
<style lang="scss">
:root {
  --white: #fff;
  --grey: #f4f7f9;
  --grey-v-2: #e8ebf0;
  --grey-v-3: #ababab;
  --grey-v-4: #f5f5f5;
  --grey-v-5: #f8f9fa;
  --flat-grey: #474747;
  --grey-secondary: #a7a7a7;
  --flat-red: #ef1873;
  --hoki-grey: #67809f;
  --flat-green: #00c9a7;
  --dark-blue: #46618d;
  --flat-black: #2e3131;
  --flat-blue: #4460ee;
  --cascade-grey: #95a5a6;
  --ming-blue: #336e7b;
  --dark-turquoise: #0a9b9b;
  --dark-green: #035157;
  --danger: #eb1460;
  --warning: #ffa17f;
  --success: #33b792;
  --info: #3eaee9;
  --light-blue: #e4f1fe;
  --link: #22313f;
  --dark: #3a539b;
  --black: #22313f;
  --yellow: #f39c12;
  --purple: #584ea7;
  --light-danger: #f9b4cc;
  --light-link: #bdc2c6;
  --light-dark: #c4cce1;
  --light-warning: #ffe3d9;
  --light-info: #c5e7f8;
  --light-black: #bdc2c6;
  --light-success: #c2eade;
  --light-green: #90cec7;
}

[data-theme="dark"] {
  --white: #1b1c37;
  --grey: #242243;
  /* v-1
  --white: #141824;
    --grey: #171b28;*/
  /*v-2
        --white: #141414;
    --grey: #0f0f10;*/
  /*v-3
  --white: #050d24;
    --grey: #07102c;*/
  --grey-v-2: #f2f2f2;
  --grey-v-3: #eee;
  --grey-v-4: #ddd;
  --flat-grey: #ccc;
  --grey-secondary: #ccc;
  --flat-red: #ef1873;
  --hoki-grey: #67809f;
  --flat-green: #00c9a7;
  --dark-blue: #46618d;
  --flat-black: #f2f2f2;
  --flat-blue: #4460ee;
  --cascade-grey: #f2f2f2;
  --ming-blue: #336e7b;
  --dark-turquoise: #0a9b9b;
  --dark-green: #035157;
  --danger: #eb1460;
  --warning: #ffa17f;
  --success: #33b792;
  --info: #3eaee9;
  --light-blue: #e4f1fe;
  --link: #f2f2f2;
  --dark: #f2f2f2;
  --black: #f2f2f2;
  --yellow: #f39c12;
  --purple: #584ea7;
  --light-danger: #f9b4cc;
  --light-link: #bdc2c6;
  --light-dark: #c4cce1;
  --light-warning: #ffe3d9;
  --light-info: #c5e7f8;
  --light-black: #bdc2c6;
  --light-success: #c2eade;
  --light-green: #90cec7;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
input,
select,
textarea,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-size: 14px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

img {
  border: 0;
}

:focus {
  outline: 0;
}

.clear {
  clear: both;
}

*,
::after,
::before {
  box-sizing: border-box;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 5px 0;
  padding: 0;
  line-height: 1.5;
}
p {
  line-height: 1.5;
}
* {
  font-size: 14px;
}
h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 23px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul {
  list-style: none;
}
.text-center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}
button,
input,
select,
textarea {
  margin: 0;
  border: none;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-size: 14px;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  td,
  th {
    padding: 0;
    text-align: left;
  }
}
a {
  text-decoration: none;
}
body {
  background: rgb(132, 191, 194);
  background: linear-gradient(
    90deg,
    rgba(46, 88, 158, 1) 0%,
    rgba(46, 88, 158, 1) 5%,
    rgba(132, 191, 194, 1) 100%
  );
}
.bg-none {
  background: none;
}
.wrapper {
  .container {
    display: block;
    max-width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    @media only screen and (min-width: 1350px) {
      width: 1230px;
    }
    @media only screen and (min-width: 991px) and (max-width: 1350px) {
      width: 85%;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 90%;
    }
    @media only screen and (max-width: 767px) {
      width: 95% !important;
    }
  }

  .event-none {
    pointer-events: none;
  }
  .position-relative {
    position: relative;
  }
}
[lazy="loaded"] {
  transition: 0.5s;
  animation: imgLoad 0.5s;
  @keyframes imgLoad {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
</style>
