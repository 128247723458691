<template>
  <section
    id="providers"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <h4>
      <span class="left"></span><span class="right"></span
      >{{ $t("Our Technology Providers<") }}
    </h4>
    <div class="container">
      <div ref="swiper" class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="item in list" :key="item.id" class="swiper-slide">
            <img v-lazy="item.image" :alt="item.title" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      swiper: null,
      list: [
        {
          id: 1,
          title: "Certik",
          image: require("../assets/images/collaborator/ctk-logo-hor-white.png"),
        },
        {
          id: 2,
          title: "IBM Cloud",
          image: require("../assets/images/collaborator/ibm-logo.png"),
        },
        {
          id: 3,
          title: "Metaco",
          image: require("../assets/images/collaborator/metaco-logo-1.png"),
        },
        {
          id: 4,
          title: "Sumsub",
          image: require("../assets/images/collaborator/sumsub.png"),
        },
      ],
    };
  },
  mounted() {
    this.startSwiper();
  },
  methods: {
    next() {
      this.swiper.slideNext(1000);
    },
    prev() {
      this.swiper.slidePrev(1000);
    },
    startSwiper() {
      if (this.swiper) {
        this.swiper.destroy();
      }
      this.$nextTick(() => {
        this.swiper = new this.$Swiper(this.$refs.swiper, {
          spaceBetween: 30,
          breakpoints: {
            0: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
          },
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
section {
  background: #224999;
  padding: 75px 0;
  -webkit-box-shadow: 0px 19px 22px 0px #00000026;
  -moz-box-shadow: 0px 19px 22px 0px #00000026;
  box-shadow: 0px 19px 22px 0px #00000026;

  @media screen and (max-width: 640px) {
    padding: 30px 0;
  }
  h4 {
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-weight: 400;
    color: var(--white);
    margin-bottom: 50px;
    position: relative;
    @media screen and (max-width: 640px) {
      font-size: 20px;
    }

    .left {
      content: "";
      width: 40%;
      height: 2px;
      background: var(--light-green);
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
      &:before {
        right: 0;
      }
      @media screen and (max-width: 1030px) {
        width: 5%;
      }
    }
    .right {
      content: "";
      width: 40%;
      height: 2px;
      background: var(--light-green);
      position: absolute;
      right: 0;
      top: calc(50% - 1px);
      &:before {
        left: 0;
      }
      @media screen and (max-width: 1030px) {
        width: 5%;
      }
    }
    .left,
    .right {
      &:before {
        content: "";
        width: 10px;
        height: 10px;
        background: var(--light-green);
        position: absolute;
        top: calc(50% - 5px);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
      }
    }
  }
  img {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
    &:hover {
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
      -moz-transform: scale(1.05);
      -ms-transform: scale(1.05);
      -o-transform: scale(1.05);
    }
  }
}
</style>
