<template>
  <section
    id="latest-video"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="container">
      <div class="video-box">
        <div v-if="!isPlay" class="filter-box">
          <h3>
            MORE PEOPLE EVERY DAY ARE BELIEVING IN THE FUTURE OF DIGITAL
            CURRENCIES
          </h3>
          <button
            v-wow="{
              'animation-name': 'pulse',
              'animation-duration': '1s',
            }"
            class="play-button"
            @click="player.playVideo(), (isPlay = true)"
          >
            <img src="@/assets/images/play-line.svg" :alt="$t('play<')" />
          </button>
        </div>
        <div ref="player" class="player-iframe"></div>
      </div>
      <div class="title-wrap">
        <h3 class="title">Latest Video</h3>
      </div>
      <div v-if="!loading" class="state">
        <h6>
          <i class="ri-restart-line"></i>
          {{ $t("Loading...<") }}
        </h6>
      </div>
      <div v-else class="position-relative">
        <div ref="swiperVideo" class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="video in videos"
              :key="video.id"
              class="swiper-slide"
              @click="setVideo(video.videoID)"
            >
              <div
                class="img cursor-pointer"
                :class="{ active: video.videoID === activeID }"
              >
                <img
                  v-lazy="
                    `https://img.youtube.com/vi/${video.videoID}/hqdefault.jpg`
                  "
                  :alt="video.title"
                />
              </div>
              <div class="content">
                <h6 class="">
                  {{ getLangTitle(video.translations) }}
                </h6>
                <p>
                  {{ getLangDescription(video.translations) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="video-arrow">
          <div class="swiper-button-prev video-prev" @click="prev">
            <img
              src="@/assets/images/arrow-left-s-line.svg"
              class="pointer-none"
              alt="arrow"
            />
            <i class="ri-arrow-left-line"></i>
          </div>
          <div class="swiper-button-next video-next" @click="next">
            <img
              src="@/assets/images/arrow-right-s-line.svg"
              class="pointer-none"
              alt="arrow"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      swiper: null,
      player: null,
      isPlay: false,
      activeID: null,
      videos: [],
      loading: false,
    };
  },
  watch: {
    videos() {
      if (this.videos.length) {
        this.activeID = this.videos[0].videoID;
        setTimeout(() => {
          this.loading = true;
          this.startSwiper();
          this.onYouTubeIframeAPIReady();
        }, 1000);
      }
    },
  },
  created() {
    this.getList();
  },

  methods: {
    getLangTitle(t) {
      const answer = t.find(
        (x) => this.$store.state.selectedLanguage === x.language
      );
      return answer.title;
    },
    getLangDescription(t) {
      const answer = t.find(
        (x) => this.$store.state.selectedLanguage === x.language
      );
      return answer.description;
    },
    getList() {
      fetch(`${process.env.VUE_APP_API_URL}/public/video`)
        .then((res) => res.json())
        .then((data) => (this.videos = data));
    },
    shortTitle(title) {
      return title.length > 30 ? title.substr(0, 27) + "..." : title;
    },
    setVideo(videoID) {
      this.activeID = videoID;
      this.player.loadVideoById(videoID);
      this.isPlay = true;
    },
    next() {
      this.swiper.slideNext(1000);
    },
    prev() {
      this.swiper.slidePrev(1000);
    },
    startSwiper() {
      if (this.swiper) {
        this.swiper.destroyed();
      }
      this.$nextTick(() => {
        this.swiper = new this.$Swiper(this.$refs.swiperVideo, {
          slidesPerView: 4,
          spaceBetween: 30,
          autoHeight: true,
          navigation: {
            nextEl: ".swiper-button-next.video-next",
            prevEl: ".swiper-button-prev.video-prev",
          },
          breakpoints: {
            0: {
              slidesPerView: 1,
            },
            500: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 4,
            },
          },
        });
      });
    },
    onYouTubeIframeAPIReady() {
      this.$nextTick(() => {
        // eslint-disable-next-line no-undef
        this.player = new window.YT.Player(this.$refs.player, {
          videoId: this.activeID,
          playerVars: {
            playsinline: 1,
            rel: 0,
            showinfo: 0,
          },
        });
      });
    },
  },
  head: {
    script: [
      {
        src: "https://www.youtube.com/iframe_api",
        defer: true,
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
section {
  padding-bottom: 75px;
  @media screen and (max-width: 640px) {
    padding-top: 100px;
  }
  .container {
    margin-top: -150px;
    z-index: 1;
    @media screen and (max-width: 640px) {
      margin-top: 0;
    }
    .state {
      height: 150px;
      padding: 20px;
      overflow: hidden;
      text-align: center;
      background: linear-gradient(
        90deg,
        #a6fbff3d 0%,
        #73ecf24d 5%,
        #062e6f42 100%
      );
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      h6 {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: var(--white);
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 13px;
      }

      &::after {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          90deg,
          #eeeeee00 0,
          #0a9b9b03 1px,
          #eeeeee00 0,
          #eeeeee29 50%
        );
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        -moz-animation: shimmer 5s infinite;
        -ms-animation: shimmer 5s infinite;
        -o-animation: shimmer 5s infinite;
        -webkit-animation: shimmer 5s infinite;
        animation: shimmer 5s infinite;

        @-moz-keyframes shimmer {
          100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
          }
        }
        @-o-keyframes shimmer {
          100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
          }
        }

        @-webkit-keyframes shimmer {
          100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
          }
        }
        @keyframes shimmer {
          100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
          }
        }
      }
    }
    .video-box {
      position: relative;
      h3 {
        font-weight: 700;
        font-family: "Poppins", sans-serif;
        font-size: 38px;
        @media screen and (max-width: 1100px) {
          font-size: 30px;
        }
        @media screen and (max-width: 991px) {
          font-size: 25px;
        }
      }
      .filter-box {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        padding: 40px 30px;
        width: 100%;
        height: calc(100% - 2px);
        background: linear-gradient(
          90deg,
          #2d7d88cf 0%,
          #2c7e88 5%,
          #000a1e4a 100%
        );
        h3 {
          max-width: 485px;
          color: var(--white);
          @media screen and (max-width: 750px) {
            display: none;
          }
        }
        .play-button {
          width: 70px;
          height: 70px;
          background: var(--light-green);
          color: var(--white);
          font-size: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: calc(50% - 25px);
          left: calc(50% - 25px);
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          transition: 0.5s all;
          -webkit-transition: 0.5s all;
          -moz-transition: 0.5s all;
          -ms-transition: 0.5s all;
          -o-transition: 0.5s all;
          cursor: pointer;
          @media screen and (max-width: 1030px) {
            width: 50px;
            height: 50px;
          }
          &:hover {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
          }
        }
      }
    }
    .title-wrap {
      margin-bottom: 30px;
      text-align: center;
      .title {
        text-align: center;
        color: var(--white);
        border: 2px solid var(--light-green);
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        display: inline-block;
        padding: 10px 50px;
        margin-top: 80px;
        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        @media screen and (max-width: 1030px) {
          font-size: 17px;
          padding: 10px 30px;
          margin-top: 50px;
        }
      }
    }

    .swiper-slide {
      .content {
        h6 {
          margin: 10px 0 5px 0;
          color: var(--white);
          font-weight: 500;
          font-family: "Poppins", sans-serif;

          @media screen and (max-width: 1100px) {
            font-size: 15px;
          }
          @media screen and (max-width: 640px) {
            font-size: 14px;
          }
        }
        p {
          color: var(--white);
          font-family: "Poppins", sans-serif;
          font-size: 13px;
          @media screen and (max-width: 640px) {
            font-size: 12px;
          }
        }
      }
      .img {
        overflow: hidden;
        height: 165px;
        position: relative;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        -o-transition: 0.5s all;
        &:before {
          content: "";
          background: linear-gradient(
            90deg,
            #2c7e88cc 0%,
            #2c7e88cc 5%,
            rgba(0, 10, 30, 0.2902) 100%
          );
          width: calc(100%);
          height: calc(100%);
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          transition: 0.5s all;
          -webkit-transition: 0.5s all;
          -moz-transition: 0.5s all;
          -ms-transition: 0.5s all;
          -o-transition: 0.5s all;
        }
        &.active {
          &:after {
            content: "";
            background: var(--white);
            width: 10px;
            height: 10px;
            z-index: 2;
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            transition: 0.5s all;
            -webkit-transition: 0.5s all;
            -moz-transition: 0.5s all;
            -ms-transition: 0.5s all;
            -o-transition: 0.5s all;
          }
        }
      }
    }
    .video-arrow {
      width: 100%;
      position: absolute;
      top: 40%;
      @media screen and (max-width: 1350px) {
        position: relative;
        top: 0;
        width: 30px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
      }
      @media screen and (max-width: 991px) {
        margin-top: 40px;
        width: 10px;
      }

      .swiper-button-prev {
        left: -40px;
      }
      .swiper-button-next {
        right: -40px;
      }
      .swiper-button-prev,
      .swiper-button-next {
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        @media screen and (max-width: 1350px) {
          width: 50px;
          height: 50px;
          border: 1px solid var(--grey);
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
        }
        @media screen and (max-width: 991px) {
          width: 40px;
          height: 40px;
        }
        &:after {
          display: none;
        }
        color: #fff;
        font-size: 28px;
        &:hover {
          transform: scale(1.4);
          -webkit-transform: scale(1.4);
          -moz-transform: scale(1.4);
          -ms-transform: scale(1.4);
          -o-transform: scale(1.4);

          @media screen and (max-width: 1350px) {
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.player-iframe {
  width: 100%;
  height: 650px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;

  @media screen and (max-width: 1030px) {
    width: 100%;
    height: 450px;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    height: 300px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    height: 200px;
  }
}
</style>
