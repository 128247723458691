var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"roadmap"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_c('h3',[_vm._v(_vm._s(_vm.$t("TMNG roadmap<")))])]),_c('div',{staticClass:"boxs"},[_c('div',{directives:[{name:"wow",rawName:"v-wow",value:({
          'animation-name': 'fadeInUp',
          'animation-duration': '1s',
        }),expression:"{\n          'animation-name': 'fadeInUp',\n          'animation-duration': '1s',\n        }"}],staticClass:"box"},[_c('h4',{staticClass:"date"},[_vm._v(_vm._s(_vm.$t("November 4th, 2022<")))]),_c('h6',[_vm._v(" "+_vm._s(_vm.$t("Indium Technology Metal<"))+" ")]),_c('p',[_c('b',[_vm._v("1.")]),_vm._v(" "+_vm._s(_vm.$t( " What is Indium? Indium is a technology metal with huge potential. It is element 49 on the periodic table of elements and it is mined in China and delivered in a bar form. It is a silver shiny lustr... <" ))+" ")]),_c('button',{on:{"click":function($event){_vm.isModal = true}}},[_c('span',[_vm._v(_vm._s(_vm.$t("Read More<")))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"fill":"#fff","d":"M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"}})])])]),_c('div',{directives:[{name:"wow",rawName:"v-wow",value:({
          'animation-name': 'fadeInUp',
          'animation-duration': '1s',
        }),expression:"{\n          'animation-name': 'fadeInUp',\n          'animation-duration': '1s',\n        }"}],staticClass:"box"},[_c('h4',{staticClass:"date"},[_vm._v(_vm._s(_vm.$t("September 22nd, 2022<")))]),_c('h6',[_vm._v(" "+_vm._s(_vm.$t("Tradition Meets Crypto<"))+" ")]),_c('p',[_c('b',[_vm._v("2.")]),_vm._v(" "+_vm._s(_vm.$t( " In less than 10 days, on October 1st, 2022, EREA World AG is hosting an event in the city of Finance - Frankfurt am Main. This event has been organized by the EREA World AG team and the founders of TM...<" ))+" ")]),_c('button',{on:{"click":function($event){_vm.isModal = true}}},[_c('span',[_vm._v(_vm._s(_vm.$t("Read More<")))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"fill":"#fff","d":"M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"}})])])]),_c('div',{directives:[{name:"wow",rawName:"v-wow",value:({
          'animation-name': 'fadeInUp',
          'animation-duration': '1s',
        }),expression:"{\n          'animation-name': 'fadeInUp',\n          'animation-duration': '1s',\n        }"}],staticClass:"box"},[_c('h4',{staticClass:"date"},[_vm._v(_vm._s(_vm.$t("August 15 th, 2022<")))]),_c('h6',[_vm._v(" "+_vm._s(_vm.$t( "TMNG schlagt die Brucke zwischen der alten und neuen Welt der Investitionen<" ))+" ")]),_c('p',[_c('b',[_vm._v("3.")]),_vm._v(" "+_vm._s(_vm.$t( "Traditionell haben die Menschen in Sachwerte wie Gold und Silber investiert, um ihr Vermogen vor Inflation zu schutzen. Andere haben in Lebensversicherungen, Aktien, Anleihen oder andere liquide Mitte..<" ))+" ")]),_c('button',{on:{"click":function($event){_vm.isModal = true}}},[_c('span',[_vm._v(_vm._s(_vm.$t("Read More<")))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"fill":"#fff","d":"M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"}})])])])]),_c('div',{directives:[{name:"wow",rawName:"v-wow",value:({
        'animation-name': 'fadeInUp',
        'animation-duration': '1s',
      }),expression:"{\n        'animation-name': 'fadeInUp',\n        'animation-duration': '1s',\n      }"}],staticClass:"text-center"},[_c('button',{staticClass:"view-more",on:{"click":function($event){_vm.isModal = true}}},[_vm._v(" "+_vm._s(_vm.$t("View more<"))+" ")])]),(_vm.isModal)?_c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal-box"},[_c('button',{staticClass:"close bg-none cursor-pointer",on:{"click":function($event){_vm.isModal = false}}},[_c('img',{attrs:{"src":require("@/assets/images/close-line.svg"),"alt":_vm.$t('Imprint<')}})]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/images/roadmapv2.png')),expression:"require('@/assets/images/roadmapv2.png')"}],attrs:{"alt":_vm.$t('TMN Road Map<')}})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }