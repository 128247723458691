<template>
  <footer
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="top">
      <Subscribe
        v-if="isSubscribe"
        :email="email"
        @close="(isSubscribe = false), (email = null)"
      />
      <div class="container">
        <div class="left">
          <h6 class="subscribe-title">
            {{ $t("Subscribe<") }}
          </h6>
          <div>
            <div class="box">
              <input
                v-model="email"
                type="text"
                :placeholder="$t('Write your email here<')"
              />
              <button
                v-if="checkEmail"
                class="cursor-pointer"
                @click="isSubscribe = true"
              >
                <img
                  src="@/assets/images/arrow-right-line.svg"
                  class="event-none"
                />
              </button>
            </div>
            <p
              v-if="email && !checkEmail"
              class="info-mail-text"
              v-html="$t('Your <b>e-mail address</b> is not a valid format.<')"
            ></p>
          </div>
        </div>
        <div class="right">
          <Social />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <div class="list">
          <button class="cursor-pointer" @click="isImprint = true">
            {{ $t("IMPRINT<") }}
          </button>
          <a href="@/assets/images/files/Data-Protection-Police.pdf" download>
            {{ $t("PRIVACY POLICY<") }}
          </a>
          <a href="@/assets/images/files/Terms-and-Conditions.pdf" download>
            {{ $t("TERMS AND CONDITIONS<") }}
          </a>
        </div>
      </div>
    </div>
    <div v-if="isImprint" class="modal">
      <div class="modal-box">
        <button class="bg-none close cursor-pointer" @click="isImprint = false">
          <img src="@/assets/images/close-line.svg" :alt="$t('Imprint<')" />
        </button>
        <h6 class="modal-title">
          {{ $t("Impressum<") }}
        </h6>
        <p>
          <span class="sub-title"> {{ $t("EREA WORLD AG<") }} </span><br />
          <span class="info-text">{{ $t("CEO:<") }}</span>
          Rebekah Jenkins<br />
          <span class="info-text">{{ $t("Email:<") }}</span>
          support@tmn-global.com<br />
          <span class="info-text">{{ $t("Phone:<") }}</span>
          +41 41 725 35 63 <br />
          <span class="info-text">{{ $t("Company Number:<") }}</span>
          CHE-231.616.592<br />
          <span class="info-text">{{ $t("Address:<") }}</span>
          Poststrasse 14, 6300 Zug, Switzerland
          <span class="info-text">{{ $t("Companies Behind TMN:<") }}</span>
          <br />
          <a
            href="https://www.emh-ag.com"
            class="text-white text-decoration-underline"
            target="_blank"
            title="www.emh-ag.com"
            >www.emh-ag.com</a
          ><br />
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
import Social from "./Social.vue";
import Subscribe from "./Subscribe.vue";
export default {
  components: { Social, Subscribe },
  data() {
    return {
      isImprint: false,
      isSubscribe: false,
      email: null,
      downloadBlocker: "",
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  },
  computed: {
    checkEmail() {
      if (this.email) {
        return this.regex.test(this.email);
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  background: #224999;
  padding: 30px 0;
  .container {
    display: flex;
    align-items: center;
    @media screen and (max-width: 640px) {
      flex-direction: column;
      justify-content: center;
    }
    .left,
    .right {
      width: 50%;
      display: flex;
      @media screen and (max-width: 640px) {
        width: 100%;
      }
    }
    .left {
      justify-content: space-between;
      @media screen and (max-width: 640px) {
        justify-content: center;
        align-items: center;
      }
      h6 {
        color: var(--white);
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        @media screen and (max-width: 1030px) {
          display: none;
        }
      }
      .info-mail-text {
        color: var(--white);
        font-weight: 500;
        font-size: 11px;
        font-family: "Poppins", sans-serif;
        margin-top: 5px;
      }
      .box {
        background: var(--white);
        width: 320px;
        height: 40px;
        overflow: hidden;
        padding: 0 20px;
        border: 2px solid var(--light-green);
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          width: 90%;
          height: 40px;
          font-family: "Poppins", sans-serif;
          font-weight: 300;
          color: #555;
        }
        button {
          background: none;
          color: var(--light-green);
          height: 20px;
          img {
            height: 20px;
          }
        }
      }
    }
    .right {
      justify-content: end;
      @media screen and (max-width: 640px) {
        margin-top: 20px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.bottom {
  padding: 100px 0;
  background: var(--light-green);
  @media screen and (max-width: 1030px) {
    padding: 50px 0;
  }

  .list {
    text-align: center;
    a,
    button {
      color: #224999;
      font-family: "Poppins", sans-serif;
      margin-right: 50px;
      font-size: 17px;
      background: none;
      font-weight: 600;
      &:last-child {
        margin-right: 0;
      }
      @media screen and (max-width: 1030px) {
        font-size: 13px;
      }
      @media screen and (max-width: 640px) {
        width: 100%;
        font-size: 12px;
        margin-right: 0;
        display: block;
      }
    }
  }
}
.modal {
  width: 100%;
  height: 100%;
  background: #00000078;
  z-index: 16;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px;

  &-box {
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 15px;
    background: #224999;
    position: relative;
    .close {
      font-size: 20px;
      color: var(--white);
      position: absolute;
      top: 15px;
      right: 15px;
    }
    h6 {
      color: var(--white);
      font-family: "Poppins", sans-serif;
      font-size: 20px;
      margin-bottom: 20px;
    }
    p {
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      color: var(--white);
      .sub-title {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px;
        display: inline-block;
      }
      .info-text {
        font-weight: 500;
        display: inline-block;
        margin-bottom: 5px;
      }
      a {
        text-decoration: underline;
        color: var(--white);
      }
    }
  }
}
</style>
