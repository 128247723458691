<template>
  <section
    id="news"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="container">
      <div class="text-center">
        <h3 class="title">Latest News</h3>
      </div>
      <div v-if="!blogs.length" class="state">
        <h6>
          <i class="ri-restart-line"></i>
          {{ $t("Loading...<") }}
        </h6>
      </div>
      <div v-else class="position-relative">
        <div
          ref="swiperBlog"
          class="swiper-container"
          @mouseleave="swiper.autoplay.stop()"
        >
          <div class="swiper-wrapper">
            <div
              v-for="blog in blogs"
              :key="blog.id"
              class="swiper-slide home-news-slide"
            >
              <div class="img">
                <a
                  :href="
                    blog.url
                      ? blog.url
                      : `https://app.tmn-global.com/posts/${blog.slug}`
                  "
                  :title="blog.title"
                  target="_blank"
                  class="back"
                  v-lazy:background-image="blog.image"
                >
                </a>
              </div>
              <div class="content">
                <a
                  :href="
                    blog.url
                      ? blog.url
                      : `https://app.tmn-global.com/posts/${blog.slug}`
                  "
                  :title="blog.title"
                  target="_blank"
                >
                  <h6>
                    {{ shortTitle(blog.title) }}
                  </h6>
                </a>

                <p>
                  {{
                    shortDescription(
                      blog.description
                        ? blog.description
                        : blog.shortDescription
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="news-arrow">
          <div class="swiper-button-prev new-prev" @click="prev">
            <img
              src="@/assets/images/arrow-left-s-line.svg"
              class="pointer-none"
              alt="arrow"
            />
            <i class="ri-arrow-left-line"></i>
          </div>
          <div class="swiper-button-next new-next" @click="next">
            <img
              src="@/assets/images/arrow-right-s-line.svg"
              class="pointer-none"
              alt="arrow"
            />
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      swiper: null,
      blog: [],
      medium: [],
    };
  },
  created() {
    this.getList();
  },
  computed: {
    blogs() {
      const socialBlog = [];
      if (this.medium.length && this.blog.length) {
        this.medium.map((n) => {
          n.languages.map((lang) => {
            const imgs = lang.body.split("</figure>");
            const select = imgs[0];
            const htmlObject = document.createElement("div");
            htmlObject.innerHTML = select;
            const image = htmlObject.querySelector("img").src;
            if (lang.lang === "en") {
              socialBlog.push({
                ...lang,
                image,
                description: lang.description.slice(0, 200),
                createdAt: n.createdAt,
                id: n.id,
              });
            }
          });
        });
        socialBlog.push(...this.blog);
      }
      return socialBlog;
    },
  },

  watch: {
    blogs() {
      if (this.blogs.length) {
        this.startSwiper();
      }
    },
  },
  methods: {
    async getList() {
      fetch(
        `${process.env.VUE_APP_API_URL}/news?` +
          new URLSearchParams({
            fields: "id image languages createdAt",
            sort: "-createdAt",
            published: true,
            type: "medium",
            limit: 5,
          })
      )
        .then((res) => res.json())
        .then((data) => (this.medium = data.docs));
      fetch(
        `${process.env.VUE_APP_API_URL}/blog?` +
          new URLSearchParams({
            sort: "-createdAt",
            published: true,
          })
      )
        .then((res) => res.json())
        .then((data) => (this.blog = data.docs));
    },
    shortDescription(desc) {
      return desc.length > 30 ? desc.substr(0, 27) + "..." : desc;
    },
    shortTitle(title) {
      return title.length > 23 ? title.substr(0, 20) + "..." : title;
    },
    // next() {
    //   this.swiper.slideNext(1000);
    // },
    // prev() {
    //   this.swiper.slidePrev(1000);
    // },
    startSwiper() {
      if (this.swiper) {
        this.swiper.destroy();
      }
      this.$nextTick(() => {
        this.swiper = new this.$Swiper(this.$refs.swiperBlog, {
          spaceBetween: 30,
          observer: true,
          enabled: true,
          createElements: true,
          autoplay: {
            delay: 2500,
            disableOnInteraction: true,
          },
          // navigation: {
          //   nextEl: ".swiper-button-next.new-next",
          //   prevEl: ".swiper-button-prev.new-prev",
          // },
          breakpoints: {
            0: {
              slidesPerView: 1,
            },
            500: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 4,
            },
          },
        });
        this.swiper.loopDestroy();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0;
  @media screen and (max-width: 1030px) {
    padding: 75px 0 100px 0;
  }
  @media screen and (max-width: 640px) {
    padding: 50px 0 70px 0;
  }
  .container {
    .title {
      text-align: center;
      color: var(--white);
      border: 2px solid var(--light-green);
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      display: inline-block;
      padding: 10px 50px;
      border-radius: 40px;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      margin-bottom: 30px;
      @media screen and (max-width: 1030px) {
        font-size: 20px;
        padding: 10px 30px;
      }
    }
    .state {
      height: 150px;
      padding: 20px;
      overflow: hidden;
      text-align: center;
      background: linear-gradient(
        90deg,
        #a6fbff3d 0%,
        #73ecf24d 5%,
        #062e6f42 100%
      );
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      h6 {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: var(--white);
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 13px;
      }

      &::after {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          90deg,
          #eeeeee00 0,
          #0a9b9b03 1px,
          #eeeeee00 0,
          #eeeeee29 50%
        );
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        -moz-animation: shimmer 5s infinite;
        -ms-animation: shimmer 5s infinite;
        -o-animation: shimmer 5s infinite;
        -webkit-animation: shimmer 5s infinite;
        animation: shimmer 5s infinite;
        @-moz-keyframes shimmer {
          100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
          }
        }
        @-o-keyframes shimmer {
          100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
          }
        }

        @-webkit-keyframes shimmer {
          100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
          }
        }
        @keyframes shimmer {
          100% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
          }
        }
      }
    }
    .swiper-slide.home-news-slide {
      .img {
        overflow: hidden;
        height: 165px;
        position: relative;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        .back {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          transition: 0.5s all;
          -webkit-transition: 0.5s all;
          -moz-transition: 0.5s all;
          -ms-transition: 0.5s all;
          -o-transition: 0.5s all;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          &:before {
            content: "";
            background: linear-gradient(
              90deg,
              #2c7e88cc 0%,
              #2c7e88cc 5%,
              rgba(0, 10, 30, 0.2902) 100%
            );
            width: calc(100%);
            height: calc(100%);
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            transition: 0.5s all;
            -webkit-transition: 0.5s all;
            -moz-transition: 0.5s all;
            -ms-transition: 0.5s all;
            -o-transition: 0.5s all;
          }
        }
      }
      .content {
        h6 {
          color: var(--white);
          font-weight: 500;
          font-family: "Poppins", sans-serif;
          margin-bottom: 0;
          @media screen and (max-width: 1100px) {
            font-size: 15px;
          }
          @media screen and (max-width: 640px) {
            font-size: 14px;
          }
        }
        p {
          color: var(--white);
          font-family: "Poppins", sans-serif;
          font-size: 13px;
          @media screen and (max-width: 640px) {
            font-size: 12px;
          }
        }
      }
    }
    .news-arrow {
      width: 100%;
      position: absolute;
      top: 40%;
      @media screen and (max-width: 1350px) {
        position: relative;
        top: 0;
        width: 30px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
      }
      @media screen and (max-width: 991px) {
        margin-top: 40px;
        width: 10px;
      }

      .swiper-button-prev {
        left: -40px;
      }
      .swiper-button-next {
        right: -40px;
      }
      .swiper-button-prev,
      .swiper-button-next {
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        @media screen and (max-width: 1350px) {
          width: 50px;
          height: 50px;
          border: 1px solid var(--grey);
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
        }
        @media screen and (max-width: 991px) {
          width: 40px;
          height: 40px;
        }
        &:after {
          display: none;
        }
        color: #fff;
        font-size: 28px;
        &:hover {
          transform: scale(1.4);
          -webkit-transform: scale(1.4);
          -moz-transform: scale(1.4);
          -ms-transform: scale(1.4);
          -o-transform: scale(1.4);
          @media screen and (max-width: 1350px) {
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
          }
        }
      }
    }
  }
}
</style>
