var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(require('@/assets/images/banner-bg.png')),expression:"require('@/assets/images/banner-bg.png')",arg:"background-image"}],attrs:{"id":"banner"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"box"},[_c('h1',{directives:[{name:"wow",rawName:"v-wow",value:({
          'animation-name': 'zoomIn',
          'animation-duration': '1s',
        }),expression:"{\n          'animation-name': 'zoomIn',\n          'animation-duration': '1s',\n        }"}],domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'Providing Global Access to Tangible Assets via the Blockchain.<'
          )
        )}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/images/switzerland-flag-clipart.svg')),expression:"require('@/assets/images/switzerland-flag-clipart.svg')"}],attrs:{"alt":_vm.$t('switzerland-flag<')}}),_c('p',{directives:[{name:"wow",rawName:"v-wow",value:({
          'animation-name': 'fadeInUp',
          'animation-duration': '2s',
        }),expression:"{\n          'animation-name': 'fadeInUp',\n          'animation-duration': '2s',\n        }"}],domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'Presented by EREA World AG. Founded in Zug, Switzerland. VQF/FINMA Regulated<'
          )
        )}}),_c('div',{directives:[{name:"wow",rawName:"v-wow",value:({
          'animation-name': 'fadeInUp',
          'animation-duration': '3s',
        }),expression:"{\n          'animation-name': 'fadeInUp',\n          'animation-duration': '3s',\n        }"}],staticClass:"btn-group"},[_c('a',{staticClass:"btn cursor-pointer",attrs:{"to":"/register"}},[_vm._v(_vm._s(_vm.$t("Register<")))]),_c('a',{staticClass:"btn",attrs:{"href":"@/assets/images/files/TMNG-White-Paper-EN.pdf","download":""}},[_vm._v(" "+_vm._s(_vm.$t("Whitepaper<"))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }