<template>
  <section
    id="team"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="container">
      <h2>
        {{ $t("Our Team<") }}
      </h2>
      <div v-if="!teams.length" class="boxs">
        <div
          v-for="(state, index) in 7"
          :key="`state-${index}`"
          class="box state"
        >
          <div class="img"></div>
        </div>
      </div>
      <div v-else class="boxs">
        <div v-for="user in teams" :key="user.id" class="box">
          <div class="img">
            <img v-lazy="user.photo" :alt="user.fullName" />
          </div>
          <h6>
            {{ user.fullName }}
          </h6>
          <p>
            {{ getLang(user.translations) }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      teams: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getLang(t) {
      const answer = t.find(
        (x) => this.$store.state.selectedLanguage === x.language
      );
      return answer.translation;
    },
    getList() {
      fetch(`${process.env.VUE_APP_API_URL}/public/teams`)
        .then((res) => res.json())
        .then((data) => (this.teams = data.filter((x) => x.type === "normal")));
    },
  },
};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
  h2 {
    font-family: "Poppins", sans-serif;
    font-size: 34px;
    color: var(--white);
    margin-bottom: 50px;
    text-align: center;
    font-weight: 600;
    @media screen and (max-width: 1100px) {
      font-size: 20px;
    }
  }
  .state {
    overflow: hidden;
    padding: 50px 40px;
    position: relative;
    &::after {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        90deg,
        #eeeeee00 0,
        #0a9b9b03 1px,
        #eeeeee00 0,
        #eeeeee29 50%
      );
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      -moz-animation: shimmer 5s infinite;
      -ms-animation: shimmer 5s infinite;
      -o-animation: shimmer 5s infinite;
      -webkit-animation: shimmer 5s infinite;
      animation: shimmer 5s infinite;

      @-moz-keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }
      @-o-keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }

      @-webkit-keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }
      @keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }
    }
    h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      color: var(--white);
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 13px;
    }
  }
  .boxs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 30px;
    column-gap: 4%;
    @media screen and (max-width: 1030px) {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 230px;
      column-gap: 30px;
      row-gap: 0;
      overflow: auto;
      padding: 0;
      justify-content: normal;
    }
    .box {
      width: 22%;
      background: var(--light-green);
      padding: 30px 40px;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-box-shadow: 0px 0px 30px 0px #0000003d;
      -moz-box-shadow: 0px 0px 30px 0px #0000003d;
      box-shadow: 0px 0px 30px 0px #0000003d;

      @media screen and (max-width: 1030px) {
        width: 100%;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
      }

      .img {
        width: 150px;
        height: 150px;
        overflow: hidden;
        text-align: center;
        border: 1px solid var(--white);
        padding: 10px;
        display: flex;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        @media screen and (max-width: 1030px) {
          width: 100px;
          height: 100px;
        }
        img {
          border: 1px solid #2249999c;
          filter: grayscale(1);
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          transition: 0.5s all;
          -webkit-transition: 0.5s all;
          -moz-transition: 0.5s all;
          -ms-transition: 0.5s all;
          -o-transition: 0.5s all;
        }
      }
      h6 {
        color: var(--white);
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        @media screen and (max-width: 640px) {
          font-size: 14px;
        }
      }
      p {
        color: var(--white);
        font-family: "Poppins", sans-serif;
        text-align: center;
        font-weight: 300;
        @media screen and (max-width: 640px) {
          font-size: 12px;
        }
      }
      &:hover {
        img {
          filter: grayscale(0);
        }
      }
    }
  }
}
</style>
