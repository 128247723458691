<template>
  <section
    id="vision-of-tmng"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="container">
      <h1 class="title">
        {{ $t("Vision of TMNG<") }}
      </h1>
      <div class="vision-img-wrap">
        <h6 class="text-1">{{ $t("Technology Metals<") }}</h6>
        <h6 class="text-2">{{ $t("Precious Metals<") }}</h6>
        <h6 class="text-3">{{ $t("Rare Earths<") }}</h6>
        <div
          v-for="(item, index) in elements.list1"
          :key="'first-' + item.id"
          class="box vision-box"
          :class="[
            `n-${index + 1} ${item.size}`,
            active === 2 ? `vision-1-${index + 1}` : 'exit',
          ]"
        >
          <span class="number">{{ item.number }}</span>
          <h6>
            {{ item.name }} <span>{{ item.fullName }}</span>
          </h6>
        </div>
        <div
          v-for="(item, index) in elements.list2"
          :key="item.id"
          class="box vision-box"
          :class="[
            `n-${index + 1} ${item.size}`,
            active === 1 ? `vision-1-${index + 1}` : 'exit',
          ]"
        >
          <span class="number">{{ item.number }}</span>
          <h6>
            {{ item.name }} <span>{{ item.fullName }}</span>
          </h6>
        </div>
        <div class="img-box">
          <span class="pulse"></span>
          <span class="pulse"></span>
          <span class="pulse"></span>
          <span class="pulse"></span>
          <div
            id="third-section-spring-ball-1"
            class="spring-ball spring-ball-1"
          ></div>
          <div
            id="third-section-spring-ball-2"
            class="spring-ball spring-ball-2"
          ></div>
          <div
            id="third-section-spring-ball-3"
            class="spring-ball spring-ball-3"
          ></div>
          <div
            id="third-section-spring-ball-4"
            class="spring-ball spring-ball-4"
          ></div>
          <div
            id="third-section-spring-ball-5"
            class="spring-ball spring-ball-5"
          ></div>
          <img
            src="@/assets/images/vision-metal.png"
            :alt="$t('Vision of TMNG<')"
          />
        </div>
      </div>
      <p class="vision-text">
        {{
          $t(
            "Stable coins can be a safe haven for the cryptocommunity because they are backed by something of physical value. However, there is not a prominent stable coin today that is really backed by reliable assets. The vision of TMNG Global is to develop a new stable coin in 2022 backed by strategic assets.For every community member who holds TMNG Tokens will receive benefits from our future stable coin. This will be the first time in cryptocurrency history where a company rewards it community for holding onto its tokens.<"
          )
        }}
      </p>
      <div class="text-center">
        <a
          class="btn"
          href="@/assets/images/files/TMNG-White-Paper-EN.pdf"
          download
        >
          {{ $t("Whitepaper<") }}
        </a>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      spinner: false,
      active: 1,
      elements: {
        list1: [
          {
            id: "elements-1",
            name: this.$t("Hf<"),
            number: "72",
            fullName: this.$t("Hafnium<"),
            size: "md",
          },
          {
            id: "elements-2",
            name: this.$t("Au<"),
            number: "78",
            fullName: this.$t("Gold<"),
            size: "xs",
          },
          {
            id: "elements-3",
            name: this.$t("In<"),
            number: "49",
            fullName: this.$t("Indium<"),
            size: "lg",
          },
          {
            id: "elements-4",
            name: this.$t("Ga<"),
            number: "31",
            fullName: this.$t("Gallium<"),
            size: "lg",
          },
          {
            id: "elements-5",
            name: this.$t("Ge<"),
            number: "32",
            fullName: this.$t("Germanium<"),
            size: "xs",
          },
          {
            id: "elements-6",
            name: this.$t("Dy<"),
            number: "66",
            fullName: this.$t("Dysprosium<"),
            size: "md",
          },
        ],
        list2: [
          {
            id: "elements-1",
            name: this.$t("Nd<"),
            number: "60",
            fullName: this.$t("Neodym<"),
            size: "md",
          },
          {
            id: "elements-2",
            name: this.$t("Pr<"),
            number: "59",
            fullName: this.$t("Praseodym<"),
            size: "xs",
          },
          {
            id: "elements-3",
            name: this.$t("Hf<"),
            number: "72",
            fullName: this.$t("Hafnium<"),
            size: "lg",
          },
          {
            id: "elements-4",
            name: this.$t("Au<"),
            number: "78",
            fullName: this.$t("Gold<"),
            size: "lg",
          },
          {
            id: "elements-5",
            name: this.$t("In<"),
            number: "49",
            fullName: this.$t("Indium<"),
            size: "xs",
          },
          {
            id: "elements-6",
            name: this.$t("Ga<"),
            number: "31",
            fullName: this.$t("Gallium<"),
            size: "md",
          },
        ],
      },
    };
  },
  mounted() {
    this.clear = setInterval(() => {
      if (this.active === 1) {
        this.active = 2;
        return;
      }
      this.active = 1;
    }, 10000);

    let springBallIntervalCount = 1;
    this.springAnimationsInterval = setInterval(() => {
      document
        .getElementById("third-section-spring-ball-" + springBallIntervalCount)
        .classList.add("spring-ball-animation-" + springBallIntervalCount);
      springBallIntervalCount++;
      if (springBallIntervalCount === 6)
        clearInterval(this.springAnimationsInterval);
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 350px;
    background: url("../assets/images/vision-bg.png") top center no-repeat;
    background-size: cover;
    margin-top: -100px;
    @media screen and (max-width: 1030px) {
      display: none;
      background-size: contain;
      height: 50px;
    }
  }

  .container {
    position: relative;
    height: 100%;
    h1 {
      font-family: "Poppins", sans-serif;
      color: var(--white);
      margin-bottom: 50px;
      text-align: center;
      font-weight: 600;
      margin-top: 65px;
      position: relative;
      @media screen and (max-width: 1100px) {
        margin-top: 0;
        font-size: 20px;
      }
      &:before {
        content: "";
        width: 2px;
        height: 65px;
        background: #90cec7;
        position: absolute;
        left: calc(50% - 1px);
        top: calc(100% + 30px);
        @media screen and (max-width: 640px) {
          display: none;
        }
      }
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background: #90cec7;
        position: absolute;
        left: calc(50% - 5px);
        top: calc(100% + 30px);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        @media screen and (max-width: 640px) {
          display: none;
        }
      }
    }
    .text {
      position: absolute;
      bottom: 25px;
    }
    .vision-text {
      position: relative;
      font-weight: 100;
      margin-top: 150px;
      text-align: center;
      padding-top: 30px;
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      color: var(--white);
      @media screen and (max-width: 1100px) {
        font-size: 12px;
      }
      &:before {
        content: "";
        width: 2px;
        height: 65px;
        background: #90cec7;
        position: absolute;
        left: calc(50% - 1px);
        top: calc(-60%);
        @media screen and (max-width: 640px) {
          display: none;
        }
      }
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background: #90cec7;
        position: absolute;
        top: calc(-60%);
        left: calc(50% - 5px);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        @media screen and (max-width: 640px) {
          display: none;
        }
      }
      @media screen and (max-width: 640px) {
        margin-top: 20px;
      }
    }
    .text-1,
    .text-2,
    .text-3 {
      color: var(--white);
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      font-size: 21px;
      @media screen and (max-width: 1030px) {
        font-size: 14px;
      }
    }
    .text-1 {
      position: absolute;
      top: -25px;
      @media screen and (max-width: 1030px) {
        top: 0;
      }
    }
    .text-2 {
      position: absolute;
      top: -25px;
      right: 0;
      @media screen and (max-width: 1030px) {
        top: 0;
      }
    }
    .text-3 {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: -25px;
    }
    .box {
      border: 1px solid var(--white);
      position: relative;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      transition: 0.5s all;
      -webkit-transition: 0.5s all;
      -moz-transition: 0.5s all;
      -ms-transition: 0.5s all;
      -o-transition: 0.5s all;
      h6 {
        color: var(--white);
        font-family: "Poppins", sans-serif;
        text-align: center;
        margin: 5px 0 0 0;
        font-size: 18px;
        font-weight: 600;
        span {
          font-size: 11px;
          font-weight: 400;
          display: block;
          border-top: 1px solid var(--white);
          padding: 5px;
          margin-top: 10px;
        }
      }

      &.n-1 {
        position: absolute;
        top: -200px;
        left: 50px;
        @media screen and (max-width: 1030px) {
          top: -150px;
        }
      }
      &.n-2 {
        position: absolute;
        top: -170px;
        right: 60px;
      }
      &.n-3 {
        position: absolute;
        top: 85px;
        left: 40px;
        @media screen and (max-width: 350px) {
          left: 10px;
        }
      }
      &.n-4 {
        position: absolute;
        top: 85px;
        right: 0;
        @media screen and (max-width: 1030px) {
          right: 40px;
        }
        @media screen and (max-width: 350px) {
          right: 30px;
        }
      }
      &.n-5 {
        position: absolute;
        bottom: 0;
        left: 170px;
        @media screen and (max-width: 1030px) {
          left: 200px;
        }
        @media screen and (max-width: 640px) {
          left: 150px;
        }
        @media screen and (max-width: 350px) {
          left: 100px;
        }
      }
      &.n-6 {
        position: absolute;
        bottom: 0;
        right: 170px;
        @media screen and (max-width: 1030px) {
          right: 200px;
        }
        @media screen and (max-width: 640px) {
          right: 150px;
        }
        @media screen and (max-width: 350px) {
          right: 100px;
        }
      }
      &.xs {
        width: 75px;
        padding-top: 20px;
        h6 {
          font-size: 16px;
          @media screen and (max-width: 1030px) {
            font-size: 14px;
          }
          span {
            font-size: 9px;
            @media screen and (max-width: 640px) {
              padding: 5px;
              font-size: 60%;
            }
          }
        }
        @media screen and (max-width: 640px) {
          width: 120px;
        }
      }
      &.md {
        width: 100px;
        padding-top: 20px;
        @media screen and (max-width: 1030px) {
          width: 80px;
          h6 {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 640px) {
          width: 120px;
        }
        span {
          @media screen and (max-width: 640px) {
            padding: 5px;
            font-size: 60%;
          }
        }
      }
      &.lg {
        width: 120px;
        padding-top: 30px;

        h6 {
          font-size: 25px;
          margin-top: 15px;
          @media screen and (max-width: 640px) {
            font-size: 14px;
          }
          span {
            padding: 10px;
            @media screen and (max-width: 640px) {
              padding: 5px;
              font-size: 60%;
            }
          }
        }
        @media screen and (max-width: 1030px) {
          width: 100px;
        }
        @media screen and (max-width: 640px) {
          width: 120px;
        }
      }
      @mixin transform($par) {
        transform: $par;
        -webkit-transform: $par;
        -moz-transform: $par;
        -ms-transform: $par;
        -o-transform: $par;
      }
      @for $i from 1 through 6 {
        $name: vision-1-#{$i};
        @keyframes vision-1-#{$i} {
          0% {
            @include transform(
              translate(random(35) + px, random(35) + px) scale(0)
            );
          }
          5% {
            @include transform(
              translate(random(35) + px, random(35) + px) scale(1)
            );
          }
          25% {
            @include transform(translate(random(35) + px, random(35) + px));
          }
          50% {
            @include transform(translate(random(35) + px, random(35) + px));
          }
          75% {
            @include transform(translate(random(35) + px, random(35) + px));
          }
          90% {
            @include transform(translate(random(35) + px, random(35) + px));
          }
          100% {
            @include transform(
              translate(random(35) + px, random(35) + px) scale(0)
            );
          }
        }
        &.vision-1-#{$i} {
          animation: 10s ease-out 0s infinite alternate $name;
        }
      }
      span.number {
        color: #fff;
        font-size: 11px;
        font-family: "Poppins", sans-serif;
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }
    .vision-img-wrap {
      height: 500px;
      position: relative;

      @media screen and (max-width: 1030px) {
        height: 440px;
      }
      @media screen and (max-width: 640px) {
        zoom: 50%;
        height: 440px;
      }
      @media screen and (max-width: 350px) {
        height: 400px;
      }
    }
    .img-box {
      width: 400px;
      height: 400px;
      border: 1px solid var(--light-green);
      margin: 130px auto 0 auto;
      padding: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      position: relative;

      @media screen and (max-width: 1030px) {
        width: 300px;
        height: 300px;
      }
      @media screen and (max-width: 350px) {
        width: 250px;
        height: 250px;
        padding: 20px;
      }
      img {
        z-index: 2;
        position: relative;
      }
      .pulse {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        transform: scale(0);
        position: absolute;
        animation: pulse 4s infinite;
      }
      .pulse:nth-child(1) {
        border: 1px solid var(--light-green);
        animation-delay: 0.4s;
      }
      .pulse:nth-child(2) {
        border: 1px solid var(--light-green);
        animation-delay: 0.8s;
      }
      .pulse:nth-child(3) {
        border: 1px solid var(--light-green);
        animation-delay: 1.2s;
      }
      @keyframes pulse {
        0% {
          transform: scale(0);
          opacity: 0.5;
        }
        100% {
          transform: scale(3);
          opacity: 0;
        }
      }
      .circle {
        width: 10px;
        height: 10px;
        background: var(--white);
        transition: 1s all;
        -webkit-transition: 1s all;
        -moz-transition: 1s all;
        -ms-transition: 1s all;
        -o-transition: 1s all;
        position: absolute;
        top: calc(50% - 5px);
        left: calc(50% - 5px);

        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
      }
    }
    .btn {
      background: transparent;
      padding: 20px 70px;
      border: 1px solid var(--light-green);
      font-family: "Poppins", sans-serif;
      color: var(--white);
      font-weight: 500;
      margin-top: 35px;
      font-size: 20px;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      display: inline-flex;
      @media screen and (max-width: 1030px) {
        font-size: 16px;
        padding: 15px 50px;
      }
      transition: 0.5s all;
      -webkit-transition: 0.5s all;
      -moz-transition: 0.5s all;
      -ms-transition: 0.5s all;
      -o-transition: 0.5s all;
      &:hover {
        background: var(--light-green);
      }
    }
  }
}
section .container .box.exit {
  transform: scale(0);
  transition: 1s all;
  -webkit-transition: 1s all;
  -moz-transition: 1s all;
  -ms-transition: 1s all;
  -o-transition: 1s all;
}

.spring-ball-1 {
  transform: translate(-15px, -15px) scale(0);
  border-radius: 20px;
  height: 40px;
  width: 40px;
}
.spring-ball-2 {
  transform: translate(0px, 0px) scale(1);
  border-radius: 5px;
  height: 10px;
  width: 10px;
}
.spring-ball-3 {
  transform: translate(-5px, -5px) scale(1);
  border-radius: 10px;
  height: 20px;
  width: 20px;
}
.spring-ball-4 {
  transform: translate(-10px, -10px) scale(1);
  border-radius: 15px;
  height: 30px;
  width: 30px;
}
.spring-ball-5 {
  transform: translate(-10px, -10px) scale(1);
  border-radius: 15px;
  height: 30px;
  width: 30px;
}
.spring-ball {
  position: absolute;
}
.spring-ball-animation-1 {
  -moz-animation: spring-ball-1 7s linear infinite;
  -ms-animation: spring-ball-1 7s linear infinite;
  -o-animation: spring-ball-1 7s linear infinite;
  -webkit-animation: spring-ball-1 7s linear infinite;
  animation: spring-ball-1 7s linear infinite;
}
.spring-ball-animation-2 {
  -moz-animation: spring-ball-2 4s linear infinite;
  -ms-animation: spring-ball-2 4s linear infinite;
  -o-animation: spring-ball-2 4s linear infinite;
  -webkit-animation: spring-ball-2 4s linear infinite;
  animation: spring-ball-2 4s linear infinite;
}
.spring-ball-animation-3 {
  -moz-animation: spring-ball-3 5s linear infinite;
  -ms-animation: spring-ball-3 5s linear infinite;
  -o-animation: spring-ball-3 5s linear infinite;
  -webkit-animation: spring-ball-3 5s linear infinite;
  animation: spring-ball-3 5s linear infinite;
}
.spring-ball-animation-4 {
  -moz-animation: spring-ball-4 6s linear infinite;
  -ms-animation: spring-ball-4 6s linear infinite;
  -o-animation: spring-ball-4 6s linear infinite;
  -webkit-animation: spring-ball-4 6s linear infinite;
  animation: spring-ball-4 6s linear infinite;
}
.spring-ball-animation-5 {
  -moz-animation: spring-ball-5 5s linear infinite;
  -ms-animation: spring-ball-5 5s linear infinite;
  -o-animation: spring-ball-5 5s linear infinite;
  -webkit-animation: spring-ball-5 5s linear infinite;
  animation: spring-ball-5 5s linear infinite;
}

@keyframes spring-ball-1 {
  0% {
    transform: translate(-15px, -15px) scale(0);
  }
  20% {
    transform: translate(300px, -250px) scale(1);
    background: var(--white);
  }
  21% {
    transform: translate(300px, -250px) scale(0);
  }
  100% {
    transform: translate(-15px, -15px) scale(0);
  }
}
@keyframes spring-ball-5 {
  0% {
    transform: translate(0px, 0px) scale(0);
  }
  20% {
    transform: translate(-300px, -250px) scale(1);
    background: var(--light-green);
  }
  25% {
    transform: translate(-300px, -250px) scale(0);
  }
  100% {
    transform: translate(0px, 0px) scale(0);
  }
}
@keyframes spring-ball-4 {
  0% {
    transform: translate(0px, 0px) scale(0);
  }
  20% {
    transform: translate(-300px, 0px) scale(1);
    background: var(--light-green);
  }
  25% {
    transform: translate(-300px, 0px) scale(0);
  }
  100% {
    transform: translate(0px, 0px) scale(0);
  }
}
@keyframes spring-ball-2 {
  0% {
    transform: translate(0px, 0px) scale(0);
  }
  20% {
    transform: translate(-300px, 200px) scale(1);
    background: var(--white);
  }
  25% {
    transform: translate(-300px, 200px) scale(0);
  }
  100% {
    transform: translate(0px, 0px) scale(0);
  }
}
@keyframes spring-ball-3 {
  0% {
    transform: translate(0px, 0px) scale(0);
  }
  20% {
    transform: translate(300px, 200px) scale(1);
    background: var(--white);
  }
  25% {
    transform: translate(300px, 200px) scale(0);
  }
  100% {
    transform: translate(0px, 0px) scale(0);
  }
}
</style>
