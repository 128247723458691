<template>
  <section
    id="tmng-token-details"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="container">
      <h2 class="title">
        {{ $t("TMNG Token Details<") }}
      </h2>
      <p class="subtitle">
        {{
          $t(
            "TMNG is an ERC-20 token and is currently selling for $0.12 per token. The minimum purchase is $250 to participate in the token sale event before exchange listings. Purchase is possible with BTC, ETH, USDT, or with a euro or swiss franc bank transfer. When using cryptocurrency to purchase, users must first complete a satoshi test before purchasing to whitelist/approve their digital wallet.<"
          )
        }}
      </p>
      <a href="" class="token-link"
        ><i class="ri-arrow-right-line"></i> {{ $t("Satoshi test guide<") }}</a
      >
      <div class="main-title">
        <h4>{{ $t("Burning Program<") }}</h4>
      </div>
      <h3>
        {{ $t("Ecosystem first utility for TMNG<") }}
      </h3>
      <div class="img-box">
        <div class="box boxes">
          <img src="@/assets/images/boxes.svg" height="40" />
          <p>{{ $t("Metal Trading Exchanges TMN Global shop<") }}</p>
        </div>
        <div class="box tmn">
          <h6>TMNG</h6>
          <p>{{ $t("Community Buys TMNG<") }}</p>
        </div>
        <div class="box flame">
          <img src="@/assets/images/flame.svg" height="40" />
          <p>{{ $t("Tokens Buy Back Burning Program<") }}</p>
        </div>
        <span class="line"></span>
        <span class="line-2"></span>
        <div class="arrow">
          <span> {{ "<" }} </span>
          <span> {{ "<" }}</span>
          <span> {{ "<" }}</span>
        </div>
        <img src="@/assets/images/earth.svg" />
      </div>
      <p class="token-text">
        {{
          $t(
            "15% of profits made by TMN Global from all projects will be used to buy back and burn TMNG tokens. An additional 10% of profits will be invested in raw materials and stored in our bonded warehouses. The burning program will reduce the total amount of 500 million tokens over time and shift the structure of the TMNG economy each burning phase. All tokens with be burned transparently on a public wallet, starting after the Token Sale Event is complete.<"
          )
        }}
      </p>
    </div>
  </section>
</template>
<script>
export default {
  name: "Token",
};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0;
  background: #224999;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
  .subtitle {
    color: var(--white);
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    text-align: center;
    @media screen and (max-width: 640px) {
      font-size: 12px;
    }
  }
  h3 {
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 40px;
    color: var(--white);
    @media screen and (max-width: 1100px) {
      font-size: 18px;
    }
  }
  .token-text {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: var(--white);
    font-weight: 100;
    text-align: center;
    margin-top: 200px;
    @media screen and (max-width: 1030px) {
      font-size: 12px;
      margin-top: 150px;
    }
    @media screen and (max-width: 640px) {
      margin-top: 100px;
    }
  }
  h2 {
    position: relative;
    text-align: center;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: var(--white);
    font-size: 24px;
    margin-bottom: 40px;
    @media screen and (max-width: 1100px) {
      font-size: 20px;
    }
    &::after {
      content: "";
      background: var(--light-green);
      width: 50px;
      height: 1px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 25px);
    }
  }
  .token-link {
    color: var(--light-green);
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-top: 20px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      line-height: normal;
      margin-right: 5px;
    }
    @media screen and (max-width: 1030px) {
      font-size: 14px;
    }
  }
  .main-title {
    text-align: center;
    margin-top: 50px;
    h4 {
      padding: 15px 50px;
      display: inline-block;
      background: var(--light-green);
      color: var(--white);
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      margin-bottom: 20px;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      @media screen and (max-width: 1030px) {
        padding: 10px 30px;
        font-size: 18px;
      }
    }
  }
  .img-box {
    width: 350px;
    height: 350px;
    margin: 0 auto;
    padding: 30px;
    border: 2px solid var(--light-green);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    position: relative;
    @media screen and (max-width: 640px) {
      zoom: 50%;
    }
    .arrow {
      width: 100%;
      height: 100%;
      animation: arrow-rotate 10s infinite;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      span {
        color: var(--light-green);
        font-family: "Poppins", sans-serif;
        font-size: 35px;
        &:nth-child(1) {
          position: absolute;
          left: calc(50% - 9px);
          bottom: -18px;
          transform: rotate(-3deg);
        }
        &:nth-child(2) {
          position: absolute;
          left: 10px;
          top: 73px;
          transform: rotate(110deg);
        }
        &:nth-child(3) {
          position: absolute;
          right: 11px;
          top: 71px;
          transform: rotate(-118deg);
        }
      }
      @keyframes arrow-rotate {
        0% {
          transform: rotate(0);
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -o-transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg);
        }
      }
    }
    .line {
      width: 84%;
      height: 84%;
      position: absolute;
      top: 8%;
      left: 8%;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      &:after {
        content: "";
        width: 50px;
        height: 30px;
        background: #224999;
        position: absolute;
        bottom: 60px;
        right: 6px;
        transform: rotate(-55deg);
        -webkit-transform: rotate(-55deg);
        -moz-transform: rotate(-55deg);
        -ms-transform: rotate(-55deg);
        -o-transform: rotate(-55deg);
      }
    }
    .line-2 {
      width: 84%;
      height: 84%;
      animation: token-rotate 6s infinite;
      position: absolute;
      top: 8%;
      left: 8%;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      &:after {
        content: "";
        width: 23px;
        height: 23px;
        background: var(--light-green);
        position: absolute;
        border-radius: 50%;
        right: 36px;
        bottom: 36px;
      }
      @keyframes token-rotate {
        0% {
          transform: rotate(0);
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -o-transform: rotate(0);
        }
        50% {
          transform: rotate(335deg);
          -webkit-transform: rotate(335deg);
          -moz-transform: rotate(335deg);
          -ms-transform: rotate(335deg);
          -o-transform: rotate(335deg);
        }
        100% {
          transform: rotate(0);
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -o-transform: rotate(0);
        }
      }
    }
    .box {
      img {
        height: 40px;
      }
      p {
        color: var(--white);
        font-family: "Poppins", sans-serif;
        font-size: 13px;
      }
      &.boxes {
        width: 150px;
        text-align: right;
        position: absolute;
        top: 50px;
        left: -170px;
      }
      &.tmn {
        width: 137px;
        display: flex;
        align-items: center;
        position: absolute;
        top: 30px;
        right: -120px;
        h6 {
          min-width: 50px;
          height: 50px;
          border: 1px solid var(--white);
          font-size: 11px;
          font-family: "Poppins", sans-serif;
          color: var(--white);
          margin: 0 10px 0 0;
          line-height: 50px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 640px) {
            font-size: 45%;
          }
        }
      }
      &.flame {
        width: 150px;
        text-align: center;
        position: absolute;
        bottom: -100px;
        left: calc(50% - 75px);
        @media screen and (max-width: 640px) {
          bottom: -150px;
        }
      }
    }
  }
}
</style>
