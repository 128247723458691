<template>
  <section id="community">
    <div class="container">
      <h2>{{ $t("Join our community!<") }}</h2>
      <div class="boxs">
        <div class="box">
          <div class="img">
            <img
              v-lazy="require('@/assets/images/community-1.jpg')"
              :alt="$t('Join our community!<')"
            />
          </div>
        </div>
        <div class="box">
          <h6>
            7,000+
            <span>members</span>
          </h6>
          <div class="img">
            <img
              v-lazy="require('@/assets/images/community-2.jpg')"
              :alt="$t('Join our community!<')"
            />
          </div>
        </div>
        <div class="box">
          <div class="img">
            <img
              v-lazy="require('@/assets/images/community-3.jpg')"
              :alt="$t('Join our community!<')"
            />
          </div>
        </div>
        <div class="box">
          <div class="img">
            <img
              v-lazy="require('@/assets/images/community-4.jpg')"
              :alt="$t('Join our community!<')"
            />
          </div>
        </div>
      </div>
      <div class="social">
        <h6>
          {{ $t("Stay connected and up to date!<") }}
        </h6>
        <Social />
      </div>
    </div>
  </section>
</template>
<script>
import Social from "./Social.vue";
export default {
  components: { Social },
};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
  h2 {
    font-family: "Poppins", sans-serif;
    font-size: 34px;
    color: var(--white);
    margin-bottom: 50px;
    text-align: center;
    font-weight: 600;
    @media screen and (max-width: 1100px) {
      font-size: 20px;
    }
  }
  .boxs {
    display: flex;
    column-gap: 2%;
    @media screen and (max-width: 640px) {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 200px;
      column-gap: 30px;
      row-gap: 0;
      overflow: auto;
      padding: 0;
      justify-content: normal;
    }
    .box {
      width: 24%;
      @media screen and (max-width: 640px) {
        width: 100%;
      }
      display: flex;
      h6 {
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 45px;
        color: var(--white);
        margin: 0;
        @media screen and (max-width: 640px) {
          font-size: 25px;
        }
      }
      span {
        display: block;
        font-size: 16px;
        font-weight: 400;
      }
      .img {
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        &:before {
          content: "";
          background: linear-gradient(
            90deg,
            rgba(0, 10, 30, 0.2902) 0%,
            rgba(0, 10, 30, 0.2902) 5%,
            #2c7e88cc 100%
          );
          width: 100%;
          height: calc(100% - 3px);
          z-index: 2;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.5s all;
          -webkit-transition: 0.5s all;
          -moz-transition: 0.5s all;
          -ms-transition: 0.5s all;
          -o-transition: 0.5s all;
        }
        img {
          transition: 0.5s all;
          -webkit-transition: 0.5s all;
          -moz-transition: 0.5s all;
          -ms-transition: 0.5s all;
          -o-transition: 0.5s all;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
        }
      }
      &:nth-child(2) {
        flex-direction: column;
        .img {
          margin-top: auto;
        }
      }
    }
  }
  .social {
    background: var(--light-green);
    padding: 15px 55px;
    margin-top: 50px;
    width: 70%;
    margin: 50px auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    @media screen and (max-width: 1250px) {
      flex-direction: column;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      width: 100%;
    }
    h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: var(--white);
      font-size: 18px;
      @media screen and (max-width: 1100px) {
        font-size: 16px;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 1250px) {
        text-align: center;
      }
    }
  }
}
</style>
