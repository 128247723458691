import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const cleanedText = (text) => {
  if (typeof text !== "string") {
    return text;
  }
  let clean = text.split("&nbsp;").join(" ");
  clean = clean.split("&amp;").join("&");
  return clean;
};
const formatMessage = (text, payload) => {
  if (payload) {
    Object.entries(payload).forEach(([key, value]) => {
      text = text.split(`{{${key}}}`).join(value);
    });
  }
  return cleanedText(text);
};

export const state = () => ({
  lang: {},
  selectedLanguage: localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en",
  languageSpinner: true,
  languages: [],
  version: 0,
});

export const getters = {
  getActiveLanguage: (state) => {
    return state.languages.find((x) => x.id === state.selectedLanguage);
  },

  GET_Lang: (state) => (code, payload) => {
    if (code[code.length - 1] !== "<") {
      return cleanedText(code);
    }
    let el = code.split("<{").join("{{");
    el = el.split("}>").join("}}");
    const message = el.slice(0, -1);
    if (state.lang && state.lang[message]) {
      return formatMessage(state.lang[message], payload);
    } else {
      return formatMessage(message, payload);
    }
  },
};

export const mutations = {
  SET_Lang: (state, lang) => {
    state.lang = lang;
  },
  SET_Languages: (state, languages) => {
    state.languages = languages;
  },
  SET_Selected_Language: (state, selected) => {
    state.selectedLanguage = selected;
  },
  SET_Language_Spinner: (state, status) => {
    state.languageSpinner = status;
  },
};

export const actions = {
  SET_Active_Language({ commit, state }, payload) {
    if (payload === state.selectedLanguage) {
      return;
    }
    commit("SET_Language_Spinner", true);
    try {
      fetch(
        `${process.env.VUE_APP_API_URL}/languages/user-language?` +
          new URLSearchParams({
            language: payload,
          })
      )
        .then((data) => data.json())
        .then(async (data) => {
          await commit("SET_Lang", data);
          commit("SET_Selected_Language", payload);
          commit("SET_Language_Spinner", false);
        });
    } catch (error) {
      console.log(error);
    }
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});
