<template>
  <section
    id="ag"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="container">
      <div class="text-center">
        <h2>
          {{ $t("What is Erea World AG?<") }}
        </h2>
        <p class="subtitle">
          {{
            $t(
              "Regulated by VQF/FINMA, EREA World AG is a blockchain firm in Zug, Switzerland, and the managing company overseeing the TMN Global platform  and native TMNG payment token<"
            )
          }}
        </p>
      </div>
      <div class="boxs">
        <div class="box">
          <div
            class="icon"
            v-lazy:background-image="require('@/assets/images/bg-icon.png')"
          >
            <img
              v-lazy="require('@/assets/images/flag-svgrepo-com.svg')"
              :alt="$t('How it started<')"
            />
          </div>
          <h5>
            {{ $t("How it started<") }}
          </h5>
          <p>
            {{
              $t(
                "EREA World AG partnered with Europäische Metallhandels (EMH) AG to develop a cryptocurrency called TMNG, which stands for Technology Metal Network Global. EMH AG is the MARKET LEADER for commodities trading in Europe.<"
              )
            }}
          </p>
          <a href="#" class="btn-link"
            ><img
              v-lazy="require('@/assets/images/arrow-right-line-green.svg')"
              :alt="$t('tmn<')"
              class="event-none"
            />
            {{ $t("Website<") }}</a
          >
        </div>
        <div class="box">
          <div
            class="icon"
            v-lazy:background-image="require('@/assets/images/bg-icon.png')"
          >
            <img
              v-lazy="require('@/assets/images/question-mark-svgrepo-com.svg')"
              :alt="$t('What is TMN Global<')"
            />
          </div>
          <h5>
            {{ $t("What is TMN Global<") }}
          </h5>
          <p>
            {{
              $t(
                "A platform which combines traditional investing in technology metals, rare earth metals and precious metals, with modern blockchain and cryptocurrency benefits.<"
              )
            }}
          </p>
          <a href="#" class="btn-link"
            ><img
              v-lazy="require('@/assets/images/arrow-right-line-green.svg')"
              :alt="$t('tmn<')"
              class="event-none"
            />
            {{ $t("Video<") }}</a
          >
        </div>
        <div class="box">
          <div
            class="icon"
            v-lazy:background-image="require('@/assets/images/bg-icon.png')"
          >
            <img
              v-lazy="
                require('@/assets/images/round-shield-with-star-svgrepo-com.svg')
              "
              :alt="$t('What is TMN Global<')"
            />
          </div>
          <h5>
            {{ $t("What is the purpose of TMNG<") }}
          </h5>
          <p>
            {{
              $t(
                "To serve as a payment token to purchase tangible assets (metals) in the TMN Global shop.<"
              )
            }}
          </p>
          <a href="#" class="btn-link"
            ><img
              v-lazy="require('@/assets/images/arrow-right-line-green.svg')"
              :alt="$t('tmn<')"
              class="event-none"
            />
            {{ $t("Video<") }}</a
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Ag",
};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
  h2 {
    border: 2px solid var(--light-green);
    display: inline-block;
    padding: 15px 35px;
    margin-bottom: 85px;
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: var(--white);
    @media screen and (max-width: 1100px) {
      font-size: 20px;
    }
    @media screen and (max-width: 640px) {
      padding: 10px 20px;
    }

    &:before {
      content: "";
      width: 2px;
      height: 65px;
      background: #90cec7;
      position: absolute;
      top: 100%;
      left: calc(50% - 1px);
    }
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #90cec7;
      position: absolute;
      left: calc(50% - 5px);
      top: calc(100% + 65px);
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    }
  }
  .subtitle {
    font-family: "Poppins", sans-serif;
    color: var(--white);
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 80px;
    @media screen and (max-width: 1100px) {
      font-size: 12px;
    }
    @media screen and (max-width: 640px) {
      margin-bottom: 40px;
    }
  }
  .boxs {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1030px) {
      display: grid;
      grid-auto-flow: column;
      grid-auto-flow: column;
      grid-auto-columns: 300px;
      column-gap: 30px;
      overflow: auto;
      padding: 0;
    }
    .box {
      width: 30%;
      @media screen and (max-width: 1030px) {
        width: 100%;
        text-align: center;
        border: 1px solid var(--light-green);
        padding: 30px 30px 50px 30px;
        border-radius: 10px;
        position: relative;
      }
      .icon {
        width: 100px;
        height: 112px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        color: var(--white);
        font-size: 60px;
        margin-bottom: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        -o-transition: 0.5s all;
        @media screen and (max-width: 1030px) {
          width: 80px;
          height: 92px;
          background-size: contain;
          margin: 0 auto 30px auto;
        }
        img {
          height: 55px;
          -webkit-transition: 0.5s all;
          -moz-transition: 0.5s all;
          -ms-transition: 0.5s all;
          -o-transition: 0.5s all;
        }
      }
      h5 {
        color: var(--white);
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 20px;

        @media screen and (max-width: 1100px) {
          font-size: 16px;
        }
        @media screen and (max-width: 640px) {
          margin-bottom: 10px;
        }
      }
      p {
        font-family: "Poppins", sans-serif;
        color: var(--white);
        font-weight: 300;
        @media screen and (max-width: 1100px) {
          font-size: 12px;
        }
      }
      .btn-link {
        color: var(--light-green);
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1030px) {
          text-align: center;
          width: 100%;
          justify-content: center;
          position: absolute;
          bottom: 20px;
          left: 0;
        }
      }
      &:hover {
        .icon {
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
        }
      }
    }
  }
}
</style>
