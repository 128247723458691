var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"wow",rawName:"v-wow",value:({
    'animation-name': 'fadeInUp',
    'animation-duration': '1s',
  }),expression:"{\n    'animation-name': 'fadeInUp',\n    'animation-duration': '1s',\n  }"}],attrs:{"id":"vision-of-tmng"}},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("Vision of TMNG<"))+" ")]),_c('div',{staticClass:"vision-img-wrap"},[_c('h6',{staticClass:"text-1"},[_vm._v(_vm._s(_vm.$t("Technology Metals<")))]),_c('h6',{staticClass:"text-2"},[_vm._v(_vm._s(_vm.$t("Precious Metals<")))]),_c('h6',{staticClass:"text-3"},[_vm._v(_vm._s(_vm.$t("Rare Earths<")))]),_vm._l((_vm.elements.list1),function(item,index){return _c('div',{key:'first-' + item.id,staticClass:"box vision-box",class:[
          `n-${index + 1} ${item.size}`,
          _vm.active === 2 ? `vision-1-${index + 1}` : 'exit',
        ]},[_c('span',{staticClass:"number"},[_vm._v(_vm._s(item.number))]),_c('h6',[_vm._v(" "+_vm._s(item.name)+" "),_c('span',[_vm._v(_vm._s(item.fullName))])])])}),_vm._l((_vm.elements.list2),function(item,index){return _c('div',{key:item.id,staticClass:"box vision-box",class:[
          `n-${index + 1} ${item.size}`,
          _vm.active === 1 ? `vision-1-${index + 1}` : 'exit',
        ]},[_c('span',{staticClass:"number"},[_vm._v(_vm._s(item.number))]),_c('h6',[_vm._v(" "+_vm._s(item.name)+" "),_c('span',[_vm._v(_vm._s(item.fullName))])])])}),_c('div',{staticClass:"img-box"},[_c('span',{staticClass:"pulse"}),_c('span',{staticClass:"pulse"}),_c('span',{staticClass:"pulse"}),_c('span',{staticClass:"pulse"}),_c('div',{staticClass:"spring-ball spring-ball-1",attrs:{"id":"third-section-spring-ball-1"}}),_c('div',{staticClass:"spring-ball spring-ball-2",attrs:{"id":"third-section-spring-ball-2"}}),_c('div',{staticClass:"spring-ball spring-ball-3",attrs:{"id":"third-section-spring-ball-3"}}),_c('div',{staticClass:"spring-ball spring-ball-4",attrs:{"id":"third-section-spring-ball-4"}}),_c('div',{staticClass:"spring-ball spring-ball-5",attrs:{"id":"third-section-spring-ball-5"}}),_c('img',{attrs:{"src":require("@/assets/images/vision-metal.png"),"alt":_vm.$t('Vision of TMNG<')}})])],2),_c('p',{staticClass:"vision-text"},[_vm._v(" "+_vm._s(_vm.$t( "Stable coins can be a safe haven for the cryptocommunity because they are backed by something of physical value. However, there is not a prominent stable coin today that is really backed by reliable assets. The vision of TMNG Global is to develop a new stable coin in 2022 backed by strategic assets.For every community member who holds TMNG Tokens will receive benefits from our future stable coin. This will be the first time in cryptocurrency history where a company rewards it community for holding onto its tokens.<" ))+" ")]),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn",attrs:{"href":"@/assets/images/files/TMNG-White-Paper-EN.pdf","download":""}},[_vm._v(" "+_vm._s(_vm.$t("Whitepaper<"))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }