<template>
  <section
    id="banner"
    v-lazy:background-image="require('@/assets/images/banner-bg.png')"
  >
    <div class="container">
      <div class="box">
        <h1
          v-wow="{
            'animation-name': 'zoomIn',
            'animation-duration': '1s',
          }"
          v-html="
            $t(
              'Providing Global Access to Tangible Assets via the Blockchain.<'
            )
          "
        ></h1>
        <img
          v-lazy="require('@/assets/images/switzerland-flag-clipart.svg')"
          :alt="$t('switzerland-flag<')"
        />
        <p
          v-wow="{
            'animation-name': 'fadeInUp',
            'animation-duration': '2s',
          }"
          class=""
          v-html="
            $t(
              'Presented by EREA World AG. Founded in Zug, Switzerland. VQF/FINMA Regulated<'
            )
          "
        ></p>
        <div
          v-wow="{
            'animation-name': 'fadeInUp',
            'animation-duration': '3s',
          }"
          class="btn-group"
        >
          <a class="btn cursor-pointer" to="/register">{{ $t("Register<") }}</a>
          <a
            class="btn"
            href="@/assets/images/files/TMNG-White-Paper-EN.pdf"
            download
          >
            {{ $t("Whitepaper<") }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Banner",
};
</script>
<style lang="scss" scoped>
section {
  height: 500px;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(132, 191, 194);
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
    background: linear-gradient(
      90deg,
      #a7faffcc 0%,
      #73ecf2e3 5%,
      #062e6fe0 100%
    );
    position: absolute;
    top: 0;
    left: 0;
  }
  .container {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 100%;
    z-index: 1;
    .box {
      max-width: 760px;
      margin: auto;
      text-align: center;
      color: var(--white);
      h1 {
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        font-size: 34px;

        @media screen and (max-width: 1100px) {
          font-size: 30px;
        }
        @media screen and (max-width: 991px) {
          font-size: 28px;
        }
        @media screen and (max-width: 640px) {
          font-size: 24px;
        }
      }

      p {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        @media screen and (max-width: 1100px) {
          font-size: 16px;
        }
        @media screen and (max-width: 991px) {
          font-size: 15px;
        }
        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
      }
      img {
        height: 30px;
        margin: 15px 0;
      }
      p {
        font-weight: 200;
        margin-bottom: 30px;
      }
      .btn-group {
        display: flex;
        justify-content: center;
        gap: 15px;
        @media screen and (max-width: 640px) {
          flex-direction: column;
        }
        .btn {
          background: var(--light-green);
          color: var(--white);
          font-family: "Poppins", sans-serif;
          line-height: normal;
          font-weight: 500;
          min-width: 200px;
          border: 2px solid transparent;
          border-radius: 30px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          transition: 0.5s all;
          -webkit-transition: 0.5s all;
          -moz-transition: 0.5s all;
          -ms-transition: 0.5s all;
          -o-transition: 0.5s all;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          @media screen and (max-width: 640px) {
            width: 90%;
            margin: 0 auto;
          }
          &:hover {
            border-color: var(--light-green);
            background: transparent;
          }
        }
      }
    }
  }
}
</style>
