<template>
  <section
    id="our-ambassadors"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="container">
      <h2>
        {{ $t("Our Ambassadors<") }}
      </h2>
      <div v-if="!loading" class="state">
        <h6>
          <i class="ri-restart-line"></i>
          {{ $t("Loading...<") }}
        </h6>
      </div>

      <div v-else class="boxs">
        <div ref="swiper" class="swiper-container">
          <div class="swiper-wrapper">
            <div v-for="user in teams" :key="user.id" class="swiper-slide">
              <a
                :href="user.shortDescription"
                target="_blank"
                :title="user.fullName"
                class="box"
              >
                <div class="img">
                  <img v-lazy="user.photo" :alt="user.fullName" />
                </div>
                <div class="content">
                  <h3>{{ user.fullName }}</h3>
                  <p>{{ user.userName }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      swiper: null,
      teams: [],
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      fetch(`${process.env.VUE_APP_API_URL}/public/teams`)
        .then((res) => res.json())
        .then((data) => (this.teams = data.filter((x) => x.type === "envoy")))
        .then(() => this.init());
    },
    init() {
      this.loading = true;
      setTimeout(() => this.startSwiper(), 300);
    },
    startSwiper() {
      if (this.swiper) {
        this.swiper.destroy();
      }
      this.$nextTick(() => {
        this.swiper = new this.$Swiper(this.$refs.swiper, {
          spaceBetween: 30,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            0: {
              slidesPerView: "auto",
            },
            1250: {
              slidesPerView: 6,
            },
          },
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
  h2 {
    font-family: "Poppins", sans-serif;
    font-size: 34px;
    margin-bottom: 50px;
    color: var(--white);
    text-align: center;
    font-weight: 600;
    @media screen and (max-width: 1100px) {
      font-size: 20px;
    }
    @media screen and (max-width: 640px) {
      margin-bottom: 30px;
    }
  }
  .state {
    height: 150px;
    padding: 20px;
    overflow: hidden;
    text-align: center;
    background: linear-gradient(
      90deg,
      #a6fbff3d 0%,
      #73ecf24d 5%,
      #062e6f42 100%
    );
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      color: var(--white);
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 13px;
    }

    &::after {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        90deg,
        #eeeeee00 0,
        #0a9b9b03 1px,
        #eeeeee00 0,
        #eeeeee29 50%
      );
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      -moz-animation: shimmer 5s infinite;
      -ms-animation: shimmer 5s infinite;
      -o-animation: shimmer 5s infinite;
      -webkit-animation: shimmer 5s infinite;
      animation: shimmer 5s infinite;

      @-moz-keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }
      @-o-keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }

      @-webkit-keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }
      @keyframes shimmer {
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
        }
      }
    }
  }
  .boxs {
    .swiper-slide {
      @media screen and (max-width: 1100px) {
        width: 150px;
      }
      .img {
        margin-bottom: 20px;
        text-align: center;

        img {
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          transition: 0.5s all;
          -webkit-transition: 0.5s all;
          -moz-transition: 0.5s all;
          -ms-transition: 0.5s all;
          -o-transition: 0.5s all;
        }
      }
      .content {
        border: 1px solid var(--white);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        h3 {
          border-bottom: 1px solid var(--white);
          padding: 10px 15px;
          text-align: center;
          font-size: 15px;
          color: var(--light-green);
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          margin: 0;
          @media screen and (max-width: 1100px) {
            font-size: 13px;
          }
          @media screen and (max-width: 640px) {
            font-size: 12px;
          }
        }
        p {
          font-family: "Poppins", sans-serif;
          color: var(--white);
          text-align: center;
          padding: 5px 15px;
          font-size: 13px;
          @media screen and (max-width: 640px) {
            font-size: 11px;
          }
        }
      }
      &:hover {
        .img {
          img {
            transform: rotate(15deg);
            -webkit-transform: rotate(15deg);
            -moz-transform: rotate(15deg);
            -ms-transform: rotate(15deg);
            -o-transform: rotate(15deg);
          }
        }
      }
    }
  }
}
</style>
