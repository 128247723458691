var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{directives:[{name:"wow",rawName:"v-wow",value:({
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }),expression:"{\n      'animation-name': 'fadeInUp',\n      'animation-duration': '1s',\n    }"},{name:"lazy",rawName:"v-lazy:background-image",value:(require('@/assets/images/metals-ag-bg.jpg')),expression:"require('@/assets/images/metals-ag-bg.jpg')",arg:"background-image"}],staticClass:"top",attrs:{"id":"about"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_c('h6',[_vm._v(_vm._s(_vm.$t("About EMH AG & The Metals<")))])]),_c('div',{staticClass:"img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/images/metal/hf.png')),expression:"require('@/assets/images/metal/hf.png')"}],attrs:{"alt":_vm.$t('About EMH AG & The Metals<')}})]),_c('div',{staticClass:"box"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "At the time of the financial crisis EMH AG was established. The idea was to give the everyday customer access to invest in technology, rare earth metals and precious metal. In cooperation with the wholesaler, EMH AG uses a new and approved duty-free warehouse only for the average customer’s investments (a one of a kind operation). Established in 2012, EMH AG’s headquarters are in Liechtenstein and they operate out of Germany, Switzerland and Austria with customers around the world.<" ))+" ")])])])]),_c('div',{directives:[{name:"wow",rawName:"v-wow",value:({
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }),expression:"{\n      'animation-name': 'fadeInUp',\n      'animation-duration': '1s',\n    }"}],staticClass:"bottom",attrs:{"id":"metals"}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("Our current tangible assets<"))+" ")]),_c('div',{ref:"swiper",staticClass:"swiper-container swiper-metal"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.list),function(item){return _c('div',{key:`metal-${item.id}`,staticClass:"swiper-slide"},[_c('div',{staticClass:"img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.image),expression:"item.image"}],attrs:{"alt":item.title}})]),_c('div',{staticClass:"content"},[_c('h3',[_c('span',{staticClass:"text-white d-block text-left font-size-11"},[_vm._v(_vm._s(item.number))]),_vm._v(" "+_vm._s(item.code)+" ")]),_c('p',[_vm._v(_vm._s(item.title))])])])}),0),_c('div',{staticClass:"swiper-pagination metal-pagination"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }