var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"wow",rawName:"v-wow",value:({
    'animation-name': 'fadeInUp',
    'animation-duration': '1s',
  }),expression:"{\n    'animation-name': 'fadeInUp',\n    'animation-duration': '1s',\n  }"}],attrs:{"id":"tse"}},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("TSE<")))]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t("270.000.000 TMNG for Sale<"))+" ")]),_c('p',{staticClass:"sub"},[_vm._v(" "+_vm._s(_vm.$t("Total supply TMNG<"))),_c('br'),_c('span',[_vm._v("500.000.000")])]),_c('div',{staticClass:"boxs"},[_c('div',{staticClass:"left-wrap"},[_c('div',{staticClass:"info-box left"},[_c('div',{staticClass:"index"},[_vm._v("1")]),_c('p',[_vm._v(_vm._s(_vm.$t("30.000.000 TMNG Stable Token and Blockchain<")))])]),_c('div',{staticClass:"info-box left"},[_c('div',{staticClass:"index"},[_vm._v("2")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("40.000.000 TMNG Strategic Partner, team and Advisors<"))+" ")])]),_c('div',{staticClass:"info-box left"},[_c('div',{staticClass:"index"},[_vm._v("3")]),_c('p',[_vm._v(_vm._s(_vm.$t("30.000.000 TMNG Exchange and operative Supply<")))])])]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"img"},[_c('span',{staticClass:"n-1"},[_vm._v("1")]),_c('span',{staticClass:"n-2"},[_vm._v("2")]),_c('span',{staticClass:"n-3"},[_vm._v("3")]),_c('span',{staticClass:"n-4"},[_vm._v("4")]),_c('span',{staticClass:"n-5"},[_vm._v("5")]),_c('span',{staticClass:"n-6"},[_vm._v("6")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/images/tse.png')),expression:"require('@/assets/images/tse.png')"}],attrs:{"alt":"TSE"}})])]),_c('div',{staticClass:"right-wrap"},[_c('div',{staticClass:"info-box right"},[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.$t(
                '90.000.000 TMNG <span class=\'home-page-span-br\'>Founders</span><'
              )
            )}}),_c('div',{staticClass:"index right"},[_vm._v("4")])]),_c('div',{staticClass:"info-box right"},[_c('p',[_vm._v(_vm._s(_vm.$t("270.000.000 TMNG Private and Public Sale<")))]),_c('div',{staticClass:"index"},[_vm._v("5")])]),_c('div',{staticClass:"info-box right"},[_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.$t(
                '40.000.000 TMNG  <span class=\'home-page-span-br\'> Marketing</span><'
              )
            )}}),_c('div',{staticClass:"index"},[_vm._v("6")])])])])]),_c('div',{staticClass:"options"},[_c('div',{staticClass:"container"},[_c('button',{staticClass:"btn"},[_vm._v(_vm._s(_vm.$t("Smart contract<")))]),_c('button',{staticClass:"btn"},[_vm._v(_vm._s(_vm.$t("Certik audit<")))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }