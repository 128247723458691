<template>
  <header ref="header" :class="{ active: isFixedHeader }">
    <div class="container">
      <a
        v-wow="{
          'animation-name': 'pulse',
          'animation-duration': '1s',
        }"
        class="logo"
        :class="{ active: isFixedHeader }"
        to="/"
        title="TMN"
      >
        <img v-lazy="require('@/assets/images/logo.png')" alt="TMN" />
      </a>
      <button class="mobile-menu-button" @click="isMobileMenu = true">
        <img
          v-lazy="require('../assets/images/menu-3-fill.svg')"
          :alt="$t('menu')"
        />
      </button>
      <span
        class="filter"
        :class="{ active: isMobileMenu }"
        @click="isMobileMenu = false"
      ></span>
      <nav id="menu" class="menu" :class="{ active: isMobileMenu }">
        <a class="close" :title="$t('Close<')" @click="isMobileMenu = false"
          ><img
            src="@/assets/images/close-line.svg"
            alt="close line event-none"
        /></a>
        <ul>
          <li>
            <button class="menu-button" @click="setSubMenu('menu')">
              {{ $t("MENU<")
              }}<img
                src="@/assets/images/arrow-down-s-line.svg"
                class="event-none"
                alt="arrow"
              />
            </button>

            <ul
              v-if="isMenu === 'menu' || isMobile"
              v-wow="{
                'animation-name': 'fadeIn',
                'animation-duration': '0.5s',
              }"
            >
              <li>
                <a
                  class="cursor-pointer"
                  :title="$t('Home<')"
                  @click="scrollTo('banner')"
                  >{{ $t("Home<") }}
                </a>
              </li>
              <li>
                <a
                  title="$t('Latest Video<')"
                  @click="scrollTo('latest-video')"
                  >{{ $t("Latest Video<") }}</a
                >
              </li>
              <li>
                <a
                  :title="$t('Our Ambassadors<')"
                  @click="scrollTo('our-ambassadors')"
                  >{{ $t("Our Ambassadors<") }}</a
                >
              </li>
              <li>
                <a
                  :title="$t('What is Erea World AG<')"
                  @click="scrollTo('ag')"
                  >{{ $t("What is Erea World AG<") }}</a
                >
              </li>
              <li>
                <a
                  :title="$t('Vision of TMNG<')"
                  @click="scrollTo('vision-of-tmng')"
                  >{{ $t("Vision of TMNG<") }}</a
                >
              </li>
              <li>
                <a
                  :title="$t('TMNG Token Details<')"
                  @click="scrollTo('tmng-token-details')"
                  >{{ $t("TMNG Token Details<") }}</a
                >
              </li>
              <li>
                <a
                  :title="$t('TMNG Token Sale Event<')"
                  @click="scrollTo('tmng-token-sale-event')"
                  >{{ $t("TMNG Token Sale Event<") }}</a
                >
              </li>
              <li>
                <a :title="$t('TSE<')" @click="scrollTo('tse')">{{
                  $t("TSE<")
                }}</a>
              </li>
              <li>
                <a
                  :title="$t('About EMH AG & The Metals<')"
                  @click="scrollTo('about')"
                  >{{ $t("About EMH AG & The Metals<") }}</a
                >
              </li>
              <li>
                <a
                  :title="$t('Our Current Tangible Assets<')"
                  @click="scrollTo('metals')"
                  >{{ $t("Our Current Tangible Assets<") }}</a
                >
              </li>
              <li>
                <a :title="$t('Latest News<')" @click="scrollTo('news')">{{
                  $t("Latest News<")
                }}</a>
              </li>
              <li>
                <a
                  :title="$t('Our Technology Providers<')"
                  @click="scrollTo('providers')"
                  >{{ $t("Our Technology Providers<") }}</a
                >
              </li>
              <li>
                <a :title="$t('Our Team<')" @click="scrollTo('team')">{{
                  $t("Our Team<")
                }}</a>
              </li>
              <li>
                <a
                  :title="$t('Join our community!<')"
                  @click="scrollTo('community')"
                  >{{ $t("Join Our Community!<") }}</a
                >
              </li>
              <li>
                <a :title="$t('TMNG Roadmap<')" @click="scrollTo('roadmap')">{{
                  $t("TMNG Roadmap<")
                }}</a>
              </li>
            </ul>
          </li>
          <li class="lang-wrap">
            <button class="lang-button" @click="setSubMenu('lang')">
              {{ $t("LANGUAGE<")
              }}<img
                src="@/assets/images/arrow-down-s-line.svg"
                class="event-none"
                alt="arrow"
              />
            </button>
            <ul
              v-if="isMenu === 'lang'"
              v-wow="{
                'animation-name': 'fadeIn',
                'animation-duration': '0.5s',
              }"
            >
              <li v-for="language in $store.state.languages" :key="language.id">
                <a
                  class="cursor-pointer"
                  :title="language.name"
                  @click="updateUserLanguage(language.id)"
                >
                  {{ language.name }}
                </a>
              </li>
            </ul>
          </li>
          <li class="login-button">
            <a href="https://app.tmn-global.com/login" :title="$t('LOGIN<')">{{
              $t("LOGIN<")
            }}</a>
          </li>
          <li class="register-button">
            <a
              href="https://app.tmn-global.com/register"
              :title="$t('REGISTER<')"
              >{{ $t("REGISTER<")
              }}<img
                src="@/assets/images/fingerprint-line.svg"
                class="event-none"
                height="15"
                alt="arrow"
            /></a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      isFixedHeader: false,
      isMenu: null,
      isMobile: false,
      isMobileMenu: false,
      languages: [],
    };
  },
  destroyed() {
    window.removeEventListener("scroll", this.setFixedHeader);
    window.removeEventListener("resize", this.menuCog);
  },
  mounted() {
    this.menuCog();
    window.addEventListener("scroll", this.setFixedHeader);
    window.addEventListener("resize", this.menuCog);
  },
  created() {
    this.$store.dispatch("SET_Active_Language", "en");
    this.getLanguages();
  },
  methods: {
    getLanguages() {
      fetch(`${process.env.VUE_APP_API_URL}/languages`)
        .then((res) => res.json())
        .then((data) => this.$store.commit("SET_Languages", data));
    },
    menuCog() {
      if (window.innerWidth < 641) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.isMenu = null;
        this.isMobileMenu = false;
      }
    },
    updateUserLanguage(languageCode) {
      this.isMenu = null;
      this.$store.dispatch("SET_Active_Language", languageCode);
      if (window.innerWidth < 641) {
        this.isMobileMenu = false;
      }
    },
    scrollTo(id) {
      const _topHeight = document.getElementById(id).offsetTop;
      window.scroll({
        top: _topHeight,
        behavior: "smooth",
      });
      this.isMenu = null;
      if (window.innerWidth < 641) {
        this.isMobileMenu = false;
      }
    },
    setSubMenu(e) {
      if (this.isMenu === e) {
        this.isMenu = null;
        return;
      }
      this.isMenu = e;
    },
    setFixedHeader() {
      if (window.scrollY > this.$refs.header.offsetHeight) {
        this.isFixedHeader = true;
      } else {
        this.isFixedHeader = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  z-index: 15;
  display: flex;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      img {
        height: 100px;
        transition: 1s all;
        -webkit-transition: 1s all;
        -moz-transition: 1s all;
        -ms-transition: 1s all;
        -o-transition: 1s all;
        @media screen and (max-width: 640px) {
          height: 65px;
        }
        &:hover {
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
        }
      }
      &.active {
        img {
          height: 60px;
        }
      }
    }
    .mobile-menu-button {
      display: none;
      @media screen and (max-width: 640px) {
        color: var(--white);
        background: transparent;
        font-size: 25px;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          line-height: normal;
        }
      }
    }
    .filter {
      width: 100%;
      height: 100vh;
      background: #2e589ed9;
      position: fixed;
      top: 0;
      left: -100%;
      transition: 0.5s all;
      -webkit-transition: 0.5s all;
      -moz-transition: 0.5s all;
      -ms-transition: 0.5s all;
      -o-transition: 0.5s all;
      &.active {
        left: 0;
      }
    }
    .menu {
      @media screen and (max-width: 640px) {
        position: fixed;
        top: 0;
        right: -300px;
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        -o-transition: 0.5s all;
        -webkit-box-shadow: -2px 0px 25px 0px #00000036;
        -moz-box-shadow: -2px 0px 25px 0px #00000036;
        box-shadow: -2px 0px 25px 0px #00000036;
        overflow: auto;
        width: 300px;
        height: 100vh;
        background: rgb(132, 191, 194);
        background: linear-gradient(
          90deg,
          #2e589e 0%,
          #2e589e 5%,
          #84bfc2 100%
        );
        padding-top: 70px;
        &.active {
          right: 0;
        }
      }
      .menu-button {
        @media screen and (max-width: 640px) {
          display: none;
        }
      }
      .close {
        display: none;
        color: var(--white);
        font-size: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
        @media screen and (max-width: 640px) {
          display: block;
        }
      }
      ul {
        display: flex;
        align-items: center;
        @media screen and (max-width: 640px) {
          flex-direction: column;
        }
        li {
          margin-right: 20px;
          position: relative;
          @media screen and (max-width: 640px) {
            margin-right: 0;
            width: 100%;
          }

          button,
          a {
            color: var(--white);
            font-size: 16px;
            font-family: "Poppins", sans-serif;
            background: transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            transition: 0.5s all;
            -webkit-transition: 0.5s all;
            -moz-transition: 0.5s all;
            -ms-transition: 0.5s all;
            -o-transition: 0.5s all;
            i {
              margin-left: 3px;
              &.flag {
                width: 25px;
                height: 15px;
                margin-left: 0;
                margin-right: 10px;
              }
            }
          }

          ul {
            width: 300px;
            background: var(--white);
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            position: absolute;
            top: 40px;
            left: 0;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 640px) {
              background: transparent;
              width: 100%;
              border-radius: 0;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              position: relative;
              top: 0;
            }
            &::before {
              content: "";
              margin: 0 0.5em;
              display: inline-block;
              border: 7px solid transparent;
              border-bottom: 8px solid white;
              border-top: 0 none;
              width: 3px;
              position: absolute;
              top: -5px;
              left: 5px;
              @media screen and (max-width: 640px) {
                display: none;
              }
            }
            li {
              margin-right: 0;
              width: 100%;
              a {
                padding: 13px 30px;
                color: var(--flat-black);
                font-family: "Poppins", sans-serif;
                border-bottom: 1px solid var(--grey-v-2);
                font-size: 14px;
                transition: 0.5s all;
                -webkit-transition: 0.5s all;
                -moz-transition: 0.5s all;
                -ms-transition: 0.5s all;
                -o-transition: 0.5s all;
                @media screen and (max-width: 640px) {
                  color: var(--white);
                  border-bottom-color: #17284524;
                }
                &:hover {
                  background: var(--grey);
                  @media screen and (max-width: 640px) {
                    background: transparent;
                  }
                }
              }
              &:first-child {
                a {
                  border-radius: 10px 10px 0px 0px;
                  -webkit-border-radius: 10px 10px 0px 0px;
                  -moz-border-radius: 10px 10px 0px 0px;
                }
              }
              &:last-child {
                a {
                  border-radius: 0 0 10px 10px;
                  -webkit-border-radius: 0 0 10px 10px;
                  -moz-border-radius: 0 0 10px 10px;
                }
              }
            }
          }
          &.lang-wrap {
            @media screen and (max-width: 640px) {
              width: 82%;
              background: var(--white);
              margin-top: 30px;
              button {
                padding: 10px;
                font-weight: 600;
                color: var(--dark);
                font-size: 14px;
                width: 100%;
              }
              a {
                color: var(--flat-black);
                font-size: 13px;
              }
            }
          }
          &.login-button {
            @media screen and (max-width: 640px) {
              width: 82%;
              background: var(--hoki-grey);
              margin-top: 10px;
              a {
                padding: 10px;
                font-weight: 600;
                color: var(--white);
                font-size: 14px;
                width: 100%;
                i {
                  display: none;
                }
              }
            }
          }
          &.register-button {
            @media screen and (max-width: 640px) {
              width: 82%;
              background: var(--ming-blue);
              margin-top: 10px;
              a {
                padding: 10px;
                font-weight: 600;
                color: var(--white);
                font-size: 14px;
                width: 100%;
                img {
                  display: none;
                }
              }
            }
          }
          &:last-child {
            margin-right: 0;
            a {
              border-bottom-color: transparent;
            }
          }
        }
      }
    }
  }
  &.active {
    background: linear-gradient(90deg, #84bfc2 0%, #84bfc2 5%, #2e589e 100%);
    padding: 0;
    -webkit-box-shadow: 0px 5px 20px 1px #0000001a;
    -moz-box-shadow: 0px 5px 20px 1px #0000001a;
    box-shadow: 0px 5px 20px 1px #0000001a;
    .menu {
      a {
        font-size: 14px;
      }
    }
  }
}
</style>
