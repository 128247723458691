var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"wow",rawName:"v-wow",value:({
    'animation-name': 'fadeInUp',
    'animation-duration': '1s',
  }),expression:"{\n    'animation-name': 'fadeInUp',\n    'animation-duration': '1s',\n  }"}],attrs:{"id":"news"}},[_c('div',{staticClass:"container"},[_vm._m(0),(!_vm.blogs.length)?_c('div',{staticClass:"state"},[_c('h6',[_c('i',{staticClass:"ri-restart-line"}),_vm._v(" "+_vm._s(_vm.$t("Loading...<"))+" ")])]):_c('div',{staticClass:"position-relative"},[_c('div',{ref:"swiperBlog",staticClass:"swiper-container",on:{"mouseleave":function($event){return _vm.swiper.autoplay.stop()}}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.blogs),function(blog){return _c('div',{key:blog.id,staticClass:"swiper-slide home-news-slide"},[_c('div',{staticClass:"img"},[_c('a',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(blog.image),expression:"blog.image",arg:"background-image"}],staticClass:"back",attrs:{"href":blog.url
                    ? blog.url
                    : `https://app.tmn-global.com/posts/${blog.slug}`,"title":blog.title,"target":"_blank"}})]),_c('div',{staticClass:"content"},[_c('a',{attrs:{"href":blog.url
                    ? blog.url
                    : `https://app.tmn-global.com/posts/${blog.slug}`,"title":blog.title,"target":"_blank"}},[_c('h6',[_vm._v(" "+_vm._s(_vm.shortTitle(blog.title))+" ")])]),_c('p',[_vm._v(" "+_vm._s(_vm.shortDescription( blog.description ? blog.description : blog.shortDescription ))+" ")])])])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"title"},[_vm._v("Latest News")])])
}]

export { render, staticRenderFns }