var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"wow",rawName:"v-wow",value:({
    'animation-name': 'fadeInUp',
    'animation-duration': '1s',
  }),expression:"{\n    'animation-name': 'fadeInUp',\n    'animation-duration': '1s',\n  }"}],attrs:{"id":"latest-video"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"video-box"},[(!_vm.isPlay)?_c('div',{staticClass:"filter-box"},[_c('h3',[_vm._v(" MORE PEOPLE EVERY DAY ARE BELIEVING IN THE FUTURE OF DIGITAL CURRENCIES ")]),_c('button',{directives:[{name:"wow",rawName:"v-wow",value:({
            'animation-name': 'pulse',
            'animation-duration': '1s',
          }),expression:"{\n            'animation-name': 'pulse',\n            'animation-duration': '1s',\n          }"}],staticClass:"play-button",on:{"click":function($event){_vm.player.playVideo(), (_vm.isPlay = true)}}},[_c('img',{attrs:{"src":require("@/assets/images/play-line.svg"),"alt":_vm.$t('play<')}})])]):_vm._e(),_c('div',{ref:"player",staticClass:"player-iframe"})]),_vm._m(0),(!_vm.loading)?_c('div',{staticClass:"state"},[_c('h6',[_c('i',{staticClass:"ri-restart-line"}),_vm._v(" "+_vm._s(_vm.$t("Loading...<"))+" ")])]):_c('div',{staticClass:"position-relative"},[_c('div',{ref:"swiperVideo",staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.videos),function(video){return _c('div',{key:video.id,staticClass:"swiper-slide",on:{"click":function($event){return _vm.setVideo(video.videoID)}}},[_c('div',{staticClass:"img cursor-pointer",class:{ active: video.videoID === _vm.activeID }},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                  `https://img.youtube.com/vi/${video.videoID}/hqdefault.jpg`
                ),expression:"\n                  `https://img.youtube.com/vi/${video.videoID}/hqdefault.jpg`\n                "}],attrs:{"alt":video.title}})]),_c('div',{staticClass:"content"},[_c('h6',{},[_vm._v(" "+_vm._s(_vm.getLangTitle(video.translations))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.getLangDescription(video.translations))+" ")])])])}),0)]),_c('div',{staticClass:"video-arrow"},[_c('div',{staticClass:"swiper-button-prev video-prev",on:{"click":_vm.prev}},[_c('img',{staticClass:"pointer-none",attrs:{"src":require("@/assets/images/arrow-left-s-line.svg"),"alt":"arrow"}}),_c('i',{staticClass:"ri-arrow-left-line"})]),_c('div',{staticClass:"swiper-button-next video-next",on:{"click":_vm.next}},[_c('img',{staticClass:"pointer-none",attrs:{"src":require("@/assets/images/arrow-right-s-line.svg"),"alt":"arrow"}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-wrap"},[_c('h3',{staticClass:"title"},[_vm._v("Latest Video")])])
}]

export { render, staticRenderFns }