<template>
  <div class="subscribe-section">
    <form
      class="subscribe-section-form font-poppins"
      @submit.prevent="addMember"
    >
      <button class="bg-none close cursor-pointer" @click="$emit('close')">
        <img src="@/assets/images/close-line.svg" />
      </button>
      <h4 class="subscribe-section-title">
        {{ $t("SUBSCRIBE<") }}
      </h4>
      <div cy="subscribeForm">
        <div>
          <input
            v-model="form.email"
            type="email"
            cy="subscribeEmail"
            :placeholder="$t('example@ex.com<')"
            required
          />
        </div>
        <div class="subscribe-section-form-info">
          <input
            v-model="form.name"
            type="text"
            cy="subscribeName"
            :placeholder="$t('name<')"
            required
          />
          <input
            v-model="form.surname"
            type="text"
            cy="subscribeSurname"
            :placeholder="$t('last name<')"
            required
          />
          <button
            type="submit"
            :disabled="spinner"
            class="cursor-pointer subscribe-section-btn"
          >
            {{ $t("Subscribe<") }}
          </button>
        </div>
      </div>
      <h3 v-if="type === 'success'" class="text-center success">
        {{ $t("GREAT, SEE YOU NEXT TIME<") }}
      </h3>
      <h3 v-else-if="type === 'error'" class="error text-center">
        {{
          $t(
            "We are currently unable to process your transaction. Please try again later.<"
          )
        }}
      </h3>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      spinner: false,
      form: {
        email: null,
        name: null,
        surname: null,
        reCAPTCHA: null,
      },
      type: "form",
    };
  },

  created() {
    this.form.email = this.email;
    const recaptchaBadgeElement = document.querySelector(".grecaptcha-badge");
    if (recaptchaBadgeElement) {
      recaptchaBadgeElement.style.visibility = "visible";
    }
  },
  mounted() {
    this.executeRecaptcha();
  },
  destroyed() {
    const recaptchaBadgeElement = document.querySelector(".grecaptcha-badge");
    if (recaptchaBadgeElement) {
      recaptchaBadgeElement.style.visibility = "hidden";
    }
  },
  methods: {
    executeRecaptcha() {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.VUE_APP_RE_CAPTCHA, {
              action: "submit",
            })
            .then((token) => {
              this.form.reCAPTCHA = token;
            });
        });
      } else {
        setTimeout(this.executeRecaptcha, 500);
      }
    },
    addMember() {
      this.spinner = true;
      fetch(`${process.env.VUE_APP_API_URL}/auth/mailchimp`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(this.form), // body data type must match "Content-Type" header
      })
        .then(async (response) => {
          const isJson = response.headers.get("content-type")
            ? response.headers.get("content-type").includes("application/json")
            : false;
          const data = isJson ? await response.json() : null;
          if (!response.ok) {
            const error = (data && data.code) || response.status;
            return Promise.reject(error);
          }
        })
        .then(() => {
          this.type = "success";
          this.spinner = false;
        })
        .catch((err) => {
          if (err === "AUTHx003") {
            window.grecaptcha
              .execute(process.env.VUE_APP_RE_CAPTCHA, {
                action: "submit",
              })
              .then((token) => {
                this.form.reCAPTCHA = token;
                this.addMember();
              });
            return;
          }

          this.type = "error";
          this.spinner = false;
        });
    },
  },
  head: {
    script: [
      {
        src: `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RE_CAPTCHA}`,
        async: true,
        defer: true,
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
.success {
  font-weight: 500;
  font-size: 16px;
  color: var(--flat-green);
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  @media screen and (max-width: 650px) {
    font-size: 13px;
  }
}
.error {
  font-weight: 500;
  font-size: 16px;
  color: var(--yellow);
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  @media screen and (max-width: 650px) {
    font-size: 13px;
  }
}
.subscribe-section-title {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: 500;
  color: var(--white);
}
.spinner:before {
  background: #224999 !important;
}
.subscribe-try-btn {
  background: none;
}
.close {
  font-size: 20px;
  color: var(--white);
  position: absolute;
  top: 15px;
  right: 15px;
}
.subscribe-section {
  background: #00000078;
  padding: 150px 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 5;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;

  @media screen and (max-width: 1200px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 800px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 650px) {
    padding: 50px 0;
  }
  .subscribe-message {
    h3 {
      font-size: 50px;
      color: #bdc3c7;
      @media screen and (max-width: 650px) {
        font-size: 22px;
      }
    }
  }
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: #bdc3c7;
    margin: 10px 0 0;
    font-size: 22px;
    i {
      color: var(--danger);
    }
  }
  &-form {
    background: #224999;
    padding: 30px;
    max-width: 500px;
    width: 100%;
    position: relative;
    &-info {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    width: 50vw;
    @media screen and (max-width: 1350px) {
      width: 75vw;
    }
    @media screen and (max-width: 650px) {
      width: 90vw;
    }
    input {
      background: none;
      border-bottom: 2px solid #bdc3c7;
      width: 100%;
      margin: 0 0 20px;
      padding: 15px;
      font-family: "Poppins", sans-serif;
      color: var(--white);
      &::placeholder {
        color: #bdc3c7;
      }
    }
  }
  &-title {
    margin-bottom: 50px;
    color: var(--white);
  }
}
</style>
