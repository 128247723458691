<template>
  <section
    id="tse"
    v-wow="{
      'animation-name': 'fadeInUp',
      'animation-duration': '1s',
    }"
  >
    <div class="container">
      <h1 class="title">{{ $t("TSE<") }}</h1>
      <p class="description">
        {{ $t("270.000.000 TMNG for Sale<") }}
      </p>
      <p class="sub">
        {{ $t("Total supply TMNG<") }}<br />
        <span>500.000.000</span>
      </p>
      <div class="boxs">
        <div class="left-wrap">
          <div class="info-box left">
            <div class="index">1</div>
            <p>{{ $t("30.000.000 TMNG Stable Token and Blockchain<") }}</p>
          </div>
          <div class="info-box left">
            <div class="index">2</div>
            <p>
              {{ $t("40.000.000 TMNG Strategic Partner, team and Advisors<") }}
            </p>
          </div>
          <div class="info-box left">
            <div class="index">3</div>
            <p>{{ $t("30.000.000 TMNG Exchange and operative Supply<") }}</p>
          </div>
        </div>
        <div class="center">
          <div class="img">
            <span class="n-1">1</span>
            <span class="n-2">2</span>
            <span class="n-3">3</span>
            <span class="n-4">4</span>
            <span class="n-5">5</span>
            <span class="n-6">6</span>
            <img v-lazy="require('@/assets/images/tse.png')" alt="TSE" />
          </div>
        </div>
        <div class="right-wrap">
          <div class="info-box right">
            <p
              v-html="
                $t(
                  '90.000.000 TMNG <span class=\'home-page-span-br\'>Founders</span><'
                )
              "
            ></p>
            <div class="index right">4</div>
          </div>
          <div class="info-box right">
            <p>{{ $t("270.000.000 TMNG Private and Public Sale<") }}</p>
            <div class="index">5</div>
          </div>
          <div class="info-box right">
            <p
              v-html="
                $t(
                  '40.000.000 TMNG  <span class=\'home-page-span-br\'> Marketing</span><'
                )
              "
            ></p>
            <div class="index">6</div>
          </div>
        </div>
      </div>
    </div>
    <div class="options">
      <div class="container">
        <button class="btn">{{ $t("Smart contract<") }}</button>
        <button class="btn">{{ $t("Certik audit<") }}</button>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
section {
  padding: 75px 0 0 0;
  @media screen and (max-width: 640px) {
    padding: 50px 0 0 0;
  }
  .title {
    text-align: center;
    color: var(--white);
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    @media screen and (max-width: 1100px) {
      font-size: 25px;
    }
  }
  .description {
    text-align: center;
    color: var(--white);
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 18px;
    @media screen and (max-width: 1100px) {
      font-size: 12px;
    }
  }
  .sub {
    margin-top: 100px;
    margin-bottom: 70px;
    position: relative;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: var(--white);
    font-weight: 300;
    font-size: 18px;
    @media screen and (max-width: 1100px) {
      font-size: 12px;
    }
    span {
      font-weight: 600;
    }

    &:before {
      content: "";
      width: 1px;
      height: 80px;
      background: #90cec7;
      position: absolute;
      left: calc(50% - 0.5px);
      top: calc(-100% - 30px);
      @media screen and (max-width: 1030px) {
        top: calc(-100% - 50px);
      }
    }
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #90cec7;
      position: absolute;
      left: calc(50% - 5px);
      top: calc(-100% - 30px);
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      @media screen and (max-width: 1030px) {
        top: calc(-100% - 50px);
      }
    }
  }
  .boxs {
    display: grid;
    grid-template-columns: minmax(33.333%, max-content) repeat(
        auto-fill,
        33.333%
      );
    margin-bottom: 75px;
    @media screen and (max-width: 1030px) {
      align-items: center;
    }
    @media screen and (max-width: 640px) {
      display: flex;
      flex-direction: column;
    }
    .left-wrap,
    .right-wrap {
      @media screen and (max-width: 640px) {
        display: flex;
      }
    }
    .info-box {
      margin-bottom: 30px;
      display: flex;
      @media screen and (max-width: 640px) {
        zoom: 90%;
      }
      &:last-child {
        margin-bottom: 0;
      }
      @media screen and (max-width: 640px) {
        margin-bottom: 0;
      }
      p {
        color: var(--white);
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 16px;
        @media screen and (max-width: 1100px) {
          font-size: 12px;
        }
        @media screen and (max-width: 640px) {
          font-size: 10px;
        }
      }
      .index {
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        border: 1px solid var(--light-green);
        color: var(--white);
        font-size: 12px;
        font-family: "Poppins", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        position: relative;
        @media screen and (max-width: 640px) {
          font-size: 9px;
        }
        &:before {
          content: "";
          width: 80px;
          height: 1px;
          background: #90cec7;
          @media screen and (max-width: 640px) {
            width: 10px;
          }
        }
      }

      &.left {
        @media screen and (max-width: 640px) {
          display: flex;
        }
        .index {
          &:before {
            position: absolute;
            right: -80px;
            top: calc(50% - 0.5px);
            @media screen and (max-width: 640px) {
              right: initial;
              left: 20px;
            }
          }
        }
        p {
          padding-left: 90px;
          @media screen and (max-width: 640px) {
            padding-left: 20px;
          }
        }
      }
      &.right {
        justify-content: end;
        @media screen and (max-width: 640px) {
          display: flex;
          flex-direction: row-reverse;
        }

        .index {
          &:before {
            position: absolute;
            left: -80px;
            top: calc(50% - 0.5px);
            @media screen and (max-width: 640px) {
              position: absolute;
              left: 20px;
              top: calc(50% - 0.5px);
            }
          }
        }
        p {
          padding-right: 90px;
          text-align: right;
          @media screen and (max-width: 640px) {
            text-align: left;
            padding-left: 20px;
            padding-right: 0;
          }
        }
      }
    }
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 640px) {
        margin: 50px 0;
      }
      .img {
        border: 2px solid var(--light-green);
        width: 175px;
        height: 175px;
        margin: 0 auto;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        span {
          width: 20px;
          height: 20px;
          border: 1px solid #ffffff57;
          font-size: 12px;
          color: #ffffffad;
          font-family: "Poppins", sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;

          &.n-1 {
            top: 20px;
            left: 40px;
          }
          &.n-6 {
            top: 20px;
            right: 40px;
          }
          &.n-3 {
            bottom: 20px;
            left: 40px;
          }
          &.n-4 {
            bottom: 20px;
            right: 40px;
          }
          &.n-5 {
            top: calc(50% - 10px);
            right: 5px;
          }
          &.n-2 {
            top: calc(50% - 10px);
            left: 5px;
          }
        }
        img {
          height: 130px;
        }
      }
    }
  }
  .options {
    background: #224999;
    padding: 30px 0;

    .container {
      display: flex;
      justify-content: center;
      gap: 50px;
      @media screen and (max-width: 640px) {
        gap: 15px;
        flex-direction: column;
      }
      .btn {
        background: var(--light-green);
        color: var(--white);
        font-family: "Poppins", sans-serif;
        line-height: normal;
        font-weight: 500;
        min-width: 200px;
        cursor: pointer;
        border: 2px solid transparent;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        height: 50px;
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        -o-transition: 0.5s all;
        &:hover {
          border-color: var(--light-green);
          background: transparent;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.home-page-span-br {
  font-size: 16px;
  @media screen and (max-width: 1100px) {
    font-size: 12px;
  }
  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
  @media screen and (min-width: 900px) {
    display: block;
  }
}
</style>
