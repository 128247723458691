<template>
  <section>
    <div
      id="about"
      v-wow="{
        'animation-name': 'fadeInUp',
        'animation-duration': '1s',
      }"
      class="top"
      v-lazy:background-image="require('@/assets/images/metals-ag-bg.jpg')"
    >
      <div class="container">
        <div class="title">
          <h6>{{ $t("About EMH AG & The Metals<") }}</h6>
        </div>
        <div class="img">
          <img
            v-lazy="require('@/assets/images/metal/hf.png')"
            :alt="$t('About EMH AG & The Metals<')"
          />
        </div>
        <div class="box">
          <p>
            {{
              $t(
                "At the time of the financial crisis EMH AG was established. The idea was to give the everyday customer access to invest in technology, rare earth metals and precious metal. In cooperation with the  wholesaler, EMH AG uses a new and approved duty-free warehouse only for the average customer’s  investments (a one of a kind operation). Established in 2012, EMH AG’s headquarters are in Liechtenstein and they operate out of Germany, Switzerland and Austria with customers around the world.<"
              )
            }}
          </p>
        </div>
      </div>
    </div>
    <div
      id="metals"
      v-wow="{
        'animation-name': 'fadeInUp',
        'animation-duration': '1s',
      }"
      class="bottom"
    >
      <div class="container">
        <h2>
          {{ $t("Our current tangible assets<") }}
        </h2>
        <div ref="swiper" class="swiper-container swiper-metal">
          <div class="swiper-wrapper">
            <div
              v-for="item in list"
              :key="`metal-${item.id}`"
              class="swiper-slide"
            >
              <div class="img">
                <img v-lazy="item.image" :alt="item.title" />
              </div>
              <div class="content">
                <h3>
                  <span class="text-white d-block text-left font-size-11">{{
                    item.number
                  }}</span>
                  {{ item.code }}
                </h3>
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
          <div class="swiper-pagination metal-pagination"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      swiper: null,
      list: [
        {
          id: 1,
          image: require("../assets/images/metal/ga.png"),
          title: this.$t("Gallium<"),
          code: "Ga",
          number: 31,
        },
        {
          id: 2,
          image: require("../assets/images/metal/hf.png"),
          title: this.$t("Hafnium<"),
          code: "Hf",
          number: 72,
        },
        {
          id: 3,
          image: require("../assets/images/metal/ge.png"),
          title: this.$t("Germanium<"),
          code: "Ge",
          number: 32,
        },
        {
          id: 4,
          image: require("../assets/images/metal/in.png"),
          title: this.$t("Indium<"),
          code: "In",
          number: 49,
        },
        {
          id: 5,
          image: require("../assets/images/metal/nd.png"),
          title: this.$t("Neodym<"),
          code: "Nd",
          number: 60,
        },
        {
          id: 6,
          image: require("../assets/images/metal/pr.png"),
          title: this.$t("Praseodym<"),
          code: "Pr",
          number: 59,
        },

        {
          id: 7,
          image: require("../assets/images/metal/dy.png"),
          title: this.$t("Dysprosium<"),
          code: "Dy",
          number: 66,
        },

        {
          id: 8,
          image: require("../assets/images/metal/re.png"),
          title: this.$t("Rhenium<"),
          code: "Re",
          number: 75,
        },

        {
          id: 9,
          image: require("../assets/images/metal/te.png"),
          title: this.$t("Tellurium<"),
          code: "Te",
          number: 52,
        },
        {
          id: 10,
          image: require("../assets/images/metal/tb.png"),
          title: this.$t("Terbium<"),
          code: "Tb",
          number: 65,
        },

        {
          id: 11,
          image: require("../assets/images/metal/y.png"),
          title: this.$t("Yttrium<"),
          code: "Y",
          number: 39,
        },
        {
          id: 12,
          image: require("../assets/images/metal/Au.png"),
          title: this.$t("Gold<"),
          code: "Au",
          number: 79,
        },
        {
          id: 13,
          image: require("../assets/images/metal/pd.png"),
          title: this.$t("Palladium<"),
          code: "Pd",
          number: 46,
        },
        {
          id: 14,
          image: require("../assets/images/metal/pt.png"),
          title: this.$t("Platinum<"),
          code: "Pt",
          number: 78,
        },
        {
          id: 15,
          image: require("../assets/images/metal/ag.png"),
          title: this.$t("Silver<"),
          code: "Ag",
          number: 47,
        },
      ],
    };
  },
  mounted() {
    this.startSwiper();
  },
  methods: {
    next() {
      this.swiper.slideNext(1000);
    },
    prev() {
      this.swiper.slidePrev(1000);
    },
    startSwiper() {
      this.swiper = new this.$Swiper(this.$refs.swiper, {
        slidesPerView: 5,
        spaceBetween: 30,
        breakpoints: {
          0: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1100: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clicked: true,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.metal-pagination {
  bottom: 0 !important;
  span {
    background: var(--white);
    width: 10px;
    height: 10px;
  }
}
</style>
<style lang="scss" scoped>
.top {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 75px 0;
  position: relative;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(132, 191, 194);
    background: linear-gradient(
      90deg,
      #062e6fe0 0%,
      #062e6fe0 5%,
      #73ecf2e3 100%
    );
    position: absolute;
    top: 0;
    left: 0;
  }
  .container {
    z-index: 2;
    position: relative;
  }
  .title {
    text-align: center;
    margin-bottom: 30px;
    h6 {
      color: var(--white);
      font-weight: 500;
      font-family: "Poppins", sans-serif;
      display: inline-block;
      padding: 20px 40px;
      font-size: 30px;
      background: #224999;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      @media screen and (max-width: 1030px) {
        font-size: 20px;
        padding: 20px 10px;
      }
    }
  }
  .img {
    width: 300px;
    height: 300px;
    border: 2px solid var(--light-green);
    margin: auto;
    padding: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    -moz-animation: token-rotate 12s infinite;
    -ms-animation: token-rotate 12s infinite;
    -o-animation: token-rotate 12s infinite;
    -webkit-animation: token-rotate 12s infinite;
    animation: token-rotate 12s infinite;
    @media screen and (max-width: 1030px) {
      width: 200px;
      height: 200px;
    }
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background: var(--light-green);
      position: absolute;
      top: 80px;
      left: 0;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      @media screen and (max-width: 1030px) {
        width: 10px;
        height: 10px;
        top: 60px;
      }
    }
    &:after {
      content: "";
      width: 20px;
      height: 20px;
      background: var(--white);
      position: absolute;
      top: 120px;
      right: -10px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      @media screen and (max-width: 1030px) {
        width: 10px;
        height: 10px;
        right: -2.5px;
      }
    }
    @keyframes token-rotate {
      0% {
        transform: rotate(0);
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
      }
      50% {
        transform: rotate(335deg);
        -webkit-transform: rotate(335deg);
        -moz-transform: rotate(335deg);
        -ms-transform: rotate(335deg);
        -o-transform: rotate(335deg);
      }
      100% {
        transform: rotate(0);
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
      }
    }
  }
  .box {
    padding: 80px 50px;
    background: var(--white);
    margin-top: 50px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    position: relative;
    @media screen and (max-width: 1030px) {
      padding: 50px 30px;
    }
    &:before {
      content: "";
      width: 1px;
      height: 450px;
      background: var(--light-green);
      position: absolute;
      top: -50px;
      left: calc(50% - 0.5px);
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #90cec7;
      position: absolute;
      left: calc(50% - 5px);
      bottom: calc(-60%);
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
    p {
      text-align: center;
      color: #224999;
      font-size: 16px;
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      z-index: 1;
      background: var(--white);
      position: relative;
      @media screen and (max-width: 1030px) {
        font-size: 12px;
      }
    }
  }
}
.bottom {
  padding: 75px 0;
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
  h2 {
    font-family: "Poppins", sans-serif;
    font-size: 34px;
    color: var(--white);
    margin-bottom: 50px;
    font-weight: 600;
    text-align: center;
    @media screen and (max-width: 1100px) {
      font-size: 20px;
    }
  }
  .swiper-wrapper {
    padding-top: 10px;
    padding-bottom: 50px;
  }
  .swiper-metal {
    .swiper-slide {
      img {
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
      }
      &:hover {
        img {
          transform: translate(0, -10px);
          -webkit-transform: translate(0, -10px);
          -moz-transform: translate(0, -10px);
          -ms-transform: translate(0, -10px);
          -o-transform: translate(0, -10px);
        }
      }
    }
  }
  .img {
    text-align: center;
    img {
      width: 80%;
      transition: 0.5s all;
      -webkit-transition: 0.5s all;
      -moz-transition: 0.5s all;
      -ms-transition: 0.5s all;
      -o-transition: 0.5s all;
    }
  }
  .content {
    border: 1px solid var(--white);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;

    @media screen and (max-width: 1030px) {
      margin-top: 10px;
    }

    h3 {
      border-bottom: 1px solid var(--white);
      padding: 25px 15px;
      text-align: center;
      font-size: 30px;
      color: var(--white);
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      margin: 0;
      @media screen and (max-width: 1030px) {
        font-size: 16px;
        padding: 15px;
      }
      span {
        color: var(--white);
        display: block;
        text-align: left;
        font-size: 11px;
      }
    }
    p {
      color: var(--white);
      font-family: "Poppins", sans-serif;
      text-align: center;
      padding: 5px 15px;
      font-size: 13px;
      @media screen and (max-width: 1030px) {
        font-size: 11px;
      }
    }
  }
}
</style>
