import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vWow from "@gdteams/gd-wow";
import VueLazyload from "vue-lazyload";
import VueHead from "vue-head";
import Swiper from "swiper/swiper-bundle";
import "swiper/swiper-bundle.css";
window.addEventListener("resize", getYoutube);
getYoutube();
Vue.use(VueHead);

function getYoutube() {
  if (window.innerWidth < 1030) {
    Vue.prototype.$isYoutube = false;
  } else {
    Vue.prototype.$isYoutube = true;
  }
}
const loadimage = require("./assets/images/default.png");
const errorimage = require("./assets/images/default.png");

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1,
});
Vue.prototype.$t = store.getters.GET_Lang;
Vue.prototype.$Swiper = Swiper;
Vue.use(vWow);
Vue.config.productionTip = false;
new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
